import { Check, Clear, Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import TableComponent from "../../../Components/TableComponentC";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import BasicTable from "../../../Components/BasicTable";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ViewPaymentDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState("");
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [rows, setRows] = useState([]);

  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };



  const columns = [



    { id: "paymentID", name: "Erply Payment ID" },
    { id: "cardType", name: "Card Type" },
    { id: "cashChange", name: "Cash Change($)" },
    { id: "cashPaid", name: "Cash Paid($)" },
    { id: "currencyRate", name: "Rate($)" },
    { id: "sum", name: "Total($)" },
    { id: "transactionTime", name: "Transaction Time" },

  ];

  useEffect(() => {
    if (props.viewDetails.paymentTypeID) {
     
      setLoading(true);
      httpclient.get(`getPayments?env=${env.value}&typeID=${props.viewDetails.paymentTypeID}`)
        .then(({ data }) => {
       
          if (data.status === 200) {
            const paymentList = data.records.data;
            setPaymentList(paymentList); // set the state with the productVariationList fetched from the API
            setPaymentDetails("");
            setLoading(false); // set loading to false
          } else {
            console.log("Error!");
          }
        })
        .catch(error => {
          console.log(error); // log any errors to the console
          setLoading(false); // set loading to false
        });
    }
  }, [props.viewDetails.paymentTypeID]); // remove the unnecessary condition from the dependency array





  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>{`View Payment Details (${props.viewDetails.type || "_"
            })`}</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ margin: "0", padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Associated Payment Records" {...a11yProps(1)} />
              </Tabs>
            </AppBarTabs>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box pt={3}>
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Erply Payment Type</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.paymentTypeID || "-"}</Values>
                    </FlexContent>


                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Type</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.type || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Name</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.name || "-"}
                      </Values>
                    </FlexContent>

                  </Grid>
                  {/* Left Side */}

                  {/* Right Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Created Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.created_at).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Added Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.added !==
                          "0000-00-00 00:00:00" ?
                          props.viewDetails.added !==
                            "1970-01-01 00:00:00"
                            ? props.viewDetails.added !==
                              "1970-01-01 10:00:00"
                              ? props.viewDetails.added !== null
                                ? moment(props.viewDetails.added).format(
                                  "ddd, MMM Do YYYY, h:mm a"
                                )
                                : ""
                              : ""
                            : ""
                          : "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Updated Date</span> <span> : </span>
                      </FlexInnerTitle>

                      <Values>
                        {props.viewDetails.updated_at !==
                          "0000-00-00 00:00:00" ?
                          props.viewDetails.updated_at !==
                            "1970-01-01 00:00:00"
                            ? props.viewDetails.updated_at !== null
                              ? moment(props.viewDetails.updated_at).format(
                                "ddd, MMM Do YYYY, h:mm a"
                              )
                              : ""
                            : ""
                          : "-"}
                      </Values>

                    </FlexContent>
                  </Grid>

                  {/* Right Side */}


                </Grid>
              </Box>

            </TabPanel>

            {/* Product Details */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              {paymentList.length > 0 ? (
                <BasicTable
                  columns={columns}
                  rows={paymentList}


                />) : (
                <Box p={3}>
                  <span>
                    No payment record found.
                  </span>
                </Box>
              )
              }
            </TabPanel>
            {/* Product Details */}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewPaymentDialog;

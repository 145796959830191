import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import ViewMatrixDimensionDialog from "../ViewMatrixDimensionDialog";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [


  { id: "sn", name: "SN" },
  { id: "dimensionID", name: "" },
  { id: "name", name: "Name" },
  { id: "added", name: "Added Date" },
  { id: "created_at", name: "Created Date" },
  { id: "updated_at", name: "Updated Date" },
  { id: "active", name: "Active Status" },


];


const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ListMatrixDimension = () => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const navigate = useNavigate();


  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [rows, setRows] = useState([]);

  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");


  const [filterData, setFilterData] = useState({

    name: "",
    code: "",


    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({


    name: "",
    code: "",


    submit: false,
  });



  useEffect(() => {
    if (

      filterData.name === "" &&
      filterData.code === ""


    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }

    if (filterData.name === " ") filterData.name = "";
    if (filterData.code === " ") filterData.code = "";



    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(
      localStorage.getItem("matrixdimensionlist_filter")
    );
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getAllProducts()
      :
      currentpolicy.name == "" &&
        currentpolicy.code == "" &&


        currentpolicy.removed == false
        ? getAllProducts()
        : console.log("current price policy!");
  }, []);



  const getAllProducts = () => {
    setLoading(true);
    httpclient.get(`getMatrixDimension?env=${env.value}&recordsOnPage=${recordsOnPage}&page=${page}`)
      // &includeMatrixVariations=${0}  &select=productID,name
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records;
          setRows(records.data.map((record) => {
            const product = {};
            columns.forEach((column) => {
              product[column.id] = record[column.id];
            });
            return product;
          }));
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };




  const handleSort = (column) => {
    //setDirection((prevDirection) => !prevDirection);
    setDirection(!direction);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getMatrixDimension?env=${env.value}&name=${filterData.name
          }&code=${filterData.code

          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getMatrixDimension?env=${env.value}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };




  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient.get(`getMatrixDimension?env=${env.value}&name=${filterData.name
        }&code=${filterData.code

        }&sort_by=${currentColumn
        }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${recordsOnPage}&page=${page}`
      )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getMatrixDimension?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };



  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);

    submittedData.submit
      ?
      httpclient
        .get(`getMatrixDimension?env=${env.value}&name=${filterData.name
          }&code=${filterData.code

          }&sort_by=${currentColumn}
           &direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&page=${page}&recordsOnPage=${+event.target.value}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getMatrixDimension?env=${env.value}&sort_by=${currentColumn}direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };


  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getMatrixDimension?env=${env.value}&dimensionID=${row.dimensionID || row}`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records.data[0]);
          setSingleLoading(false);
        //console.log("data", data.records.data[0])

      });
  };


  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.variationID);
      setTimeout(() => {
        getAllProducts();
      }, 1000)
    }
  };




  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,


      name: filterData.name,
      code: filterData.code,


      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("matrixdimensionlist_filter", JSON.stringify(filterData));
    setLoading(true);
    if (


      filterData.name ||
      filterData.code


    ) {
      httpclient
        .get(
          `getMatrixDimension?env=${env.value}&name=${filterData.name
          }&code=${filterData.code

          }&recordsOnPage=${recordsOnPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;

            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));//setRows(records.data);
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error12345!");
          }
        });
    } else {
      getAllProducts();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };




  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Matrix Dimension</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="name"
                      value={filterData.name}
                      onChange={handleChangeFilter}
                      fullWidth
                    />
                  </Grid>




                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {
          submittedData.name ||
            submittedData.code
            //submittedData.locationInWarehouseName ||
            ? (
              <Grid item xs={12}>
                <FilteredBox>
                  <span>Filtered: </span>

                  {submittedData.name && (
                    <p>
                      <span>Name: {submittedData.name}</span>
                      <Close
                        fontSize="small"
                        onClick={() => handleRemove("name")}
                      />
                    </p>
                  )}
                  {submittedData.code && (
                    <p>
                      <span>Code: {submittedData.code}</span>
                      <Close
                        fontSize="small"
                        onClick={() => handleRemove("code")}
                      />
                    </p>
                  )}



                </FilteredBox>
              </Grid>
            ) : (
              <Box></Box>
            )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleSort={handleSort}
            handleView={handleView}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            filterData={filterData}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewMatrixDimensionDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListMatrixDimension;

import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import ViewOpeningClosingDialog from "../ViewOpeningClosingDetails";
import httpclient from "../../../utils/httpClient";

import env from "../../../utils/env";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [


  { id: "sn", name: "SN" },
  { id: "id", name: "" },
  { id: "dayID", name: "" },
  { id: "warehouseName", name: "Warehouse" },
  { id: "pointOfSaleName", name: "POS Name" },
  { id: "shiftType", name: "Shift Type" },
  { id: "created_at", name: "Created Date" },
  { id: "updated_at", name: "Updated Date" },

];


const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ListOpeningClosingDays = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [selectedID, setSelectedID] = useState("");
  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [open, setOpen] = useState(false);
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [snackStatus, setSnackStatus] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [rows, setRows] = useState([]);

  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [filterData, setFilterData] = useState({

    pointOfSaleName: "",
    warehouseName: "",

    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    pointOfSaleName: "",
    warehouseName: "",
    submit: false,
  });



  useEffect(() => {
    if (

      filterData.warehouseName === "" &&
      filterData.pointOfSaleName === ""

    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }

    if (filterData.warehouseName === " ") filterData.warehouseName = "";
    if (filterData.pointOfSaleName === " ") filterData.pointOfSaleName = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(
      localStorage.getItem("warehouseoclist_filter")
    );
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getAllWarehouses()
      :
      currentpolicy.warehouseName == "" &&
        currentpolicy.pointOfSaleName == "" &&

        currentpolicy.removed == false
        ? getAllWarehouses()
        : console.log("current price policy!");
  }, []);



  const getAllWarehouses = () => {
    setLoading(true);
    httpclient
      .get(`getOpeningClosingDays?env=${env.value}&page=${page}&recordsOnPage=${recordsOnPage}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setRows(records.data.map((record) => {
            const warehouse = {};
            columns.forEach((column) => {
              warehouse[column.id] = record[column.id];
            });
            return warehouse;
          }));
          // Set the customer data to the state
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };

  const handleSort = (column) => {
    //setDirection((prevDirection) => !prevDirection);
    setDirection(!direction);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getOpeningClosingDays?env=${env.value}&warehouseName=${filterData.warehouseName
          }&pointOfSaleName=${filterData.pointOfSaleName

          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getOpeningClosingDays?env=${env.value}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };





  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient
        .get(`getOpeningClosingDays?env=${env.value}&warehouseName=${filterData.warehouseName
          }&pointOfSaleName=${filterData.pointOfSaleName
          }&sort_by=${currentColumn
          }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getOpeningClosingDays?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };

  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);

    submittedData.submit
      ?
      httpclient
        .get(`getOpeningClosingDays?env=${env.value}&warehouseName=${filterData.warehouseName
          }&pointOfSaleName=${filterData.pointOfSaleName
          }&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}}`)
        .then(({ data }) => {
          setLoading(true);
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getOpeningClosingDays?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };

  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getOpeningClosingDays?env=${env.value}&id=${row.id || row}`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records.data[0]);
        setSingleLoading(false);

      });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.warehouseID);
      setTimeout(() => {
        getAllWarehouses();
      }, 1000)
    }

  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,

      warehouseName: filterData.warehouseName,
      pointOfSaleName: filterData.pointOfSaleName,


      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("warehouseoc_filter", JSON.stringify(filterData));
    setLoading(true);
    if (

      filterData.warehouseName ||
      filterData.pointOfSaleName

    ) {
      httpclient
        .get(
          `getOpeningClosingDays?env=${env.value}&warehouseName=${filterData.warehouseName
          }&pointOfSaleName=${filterData.pointOfSaleName

          }&recordsOnPage=${recordsOnPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        });
    } else {
      getAllWarehouses();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };




  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };



  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Header>
            <h1>List Opening/Closing Days</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >


          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>


                  <Grid item xs={12} md={6}>
                    <InputLabel>Warehouse Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="warehouseName"
                      value={filterData.warehouseName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>POS Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="pointOfSaleName"
                      value={filterData.pointOfSaleName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>



                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {
          submittedData.warehouseName ||
            submittedData.pointOfSaleName
            ? (
              <Grid item xs={12}>
                <FilteredBox>
                  <span>Filtered: </span>
                  {submittedData.warehouseName && (
                    <p>
                      <span>Warehouse Name: {submittedData.warehouseName}</span>
                      <Close
                        fontSize="small"
                        onClick={() => handleRemove("warehouseName")}
                      />
                    </p>
                  )}
                  {submittedData.pointOfSaleName && (
                    <p>
                      <span>POS Name: {submittedData.pointOfSaleName}</span>
                      <Close
                        fontSize="small"
                        onClick={() => handleRemove("pointOfSaleName")}
                      />
                    </p>
                  )}




                </FilteredBox>
              </Grid>
            ) : (
              <Box></Box>
            )}
        {/* Filter */}
        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleSort={handleSort}
            handleView={handleView}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            filterData={filterData}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewOpeningClosingDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListOpeningClosingDays;

import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList, Sync } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import {
    Box,
    Button,
    Card,
    Collapse,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    Snackbar,
    CircularProgress,
    Autocomplete,
} from "@mui/material";
//import ViewInventoryDialog from "../ViewInventoryDialog";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
    { id: "sn", name: "SN" },
    { id: "id", name: "ID" },
    { id: "ICSC", name: "ICSC" },
    { id: "Warehouse", name: "Warehouse" },
    { id: "Size", name: "Size" },
    { id: "Colour", name: "Color" },
    { id: "pendingProcess", name: "Pending Process" },
    { id: "pswSohPending", name: "PSW SOH Pending" },
    { id: "ModifiedDateTime", name: "Last Synced On" },
    { id: "url1", name: "Resync" },


];


const FilteredBox = styled(Box)(({ theme }) => ({
    background: "#f9f9f9",
    padding: "5px 10px",
    borderRadius: "5px",
    "& p": {
        margin: "0",
        marginRight: "10px",
        display: "inline-block",
        background: "#dedede",
        borderRadius: "10px",
        padding: "2px 5px",
    },
    "& svg": {
        fontSize: "15px",
        cursor: "pointer",
        position: "relative",
        top: "3px",
        background: theme.palette.primary.dark,
        color: "#fff",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "2px",
    },
}));

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ResyncSOH = () => {
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewDetails, setViewDetails] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [deletingRow, setDeletingRow] = useState(null);
    const [singleLoading, setSingleLoading] = useState(false);
    //const [direction, setDirection] = useState(false);
    var [direction, setDirection] = useState("");
    const [currentColumn, setCurrentColumn] = useState("");
    const [rows, setRows] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(
        configRecordsOnPage && configRecordsOnPage
            ? configRecordsOnPage && configRecordsOnPage
            : 20
    );


    const [recordsOnPage, setRecordsOnPage] = useState(
        configRecordsOnPage && configRecordsOnPage
            ? configRecordsOnPage && configRecordsOnPage
            : 20
    );
    const [total, setTotal] = useState("");
    const [totalWarehouse, setTotalWarehouse] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterOpenSync, setFilterOpenSync] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");


    const [filterData, setFilterData] = useState({
        Item: "",
        Size: "",
        ProductType: "",
        Warehouse: "",
        WarehouseName: "",
        remove: false,
    });

    const [submittedData, setSubmittedData] = useState({
        Item: "",
        Size: "",
        ProductType: "",
        Warehouse: "",
        WarehouseName: "",
        submit: false,
    });



    useEffect(() => {
        if (
            filterData.Item === "" &&
            filterData.Size === "" &&
            filterData.ProductType === "" &&
            filterData.Warehouse === "" &&
            filterData.WarehouseName === ""

        ) {
            setSubmittedData({
                ...submittedData,
                submit: false,
            });
        }
        if (filterData.Item === " ") filterData.Item = "";
        if (filterData.Size === " ") filterData.Size = "";
        if (filterData.ProductType === " ") filterData.ProductType = "";
        if (filterData.Warehouse === " ") filterData.Warehouse = "";
        if (filterData.WarehouseName === " ") filterData.WarehouseName = "";


        filterData.remove === true && handleFilter();
    }, [filterData]);

    useEffect(() => {
        getAllWarehouses();
        let currentpolicy = JSON.parse(
            localStorage.getItem("resyncsohlist_filter")
        );
        currentpolicy !== null && setFilterData(currentpolicy);

        currentpolicy == null
            ? getResyncProduct()
            : currentpolicy.Item == "" &&
                currentpolicy.Size == "" &&
                currentpolicy.ProductType == "" &&
                currentpolicy.Warehouse == "" &&
                currentpolicy.WarehouseName == "" &&
                currentpolicy.removed == false
                ? getResyncProduct()
                : console.log("current price policy!");
    }, []);

    const handleSync = (id) => {
        setLoading1(true);
        setDeletingRow(id);
        httpclient.get(`magic/v1/resyncItemByLocation?env=${env.value}&id=${id}`)
            .then(({ data }) => {
                if (data.status === 200) {
                    setLoading1(false);
                    setOpen(true);
                    setMessageState("success");
                    setMessage(data.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading1(false);
            });
    };

    const getAllWarehouses = () => {
        setLoading(true);
        httpclient
            .get(`getWarehouses?env=${env.value}&recordsOnPage=${50}`)
            .then(({ data }) => {
                if (data.status === 200) {
                    setWarehouses(data.records.data)

                } else {
                    console.log("Error!");
                }
            });
    };

    const getResyncProduct = () => {
        setLoading(true);
        httpclient.get(`https://psw.synccare.com.au/php/api/magic/v1/getItemByLocations?env=${env.value}&recordsOnPage=${recordsOnPage}&page=${page}`)
            // &includeMatrixVariations=${0}
            .then(({ data }) => {
                if (data.status === 200) {
                    const records = data.records;
                    setRows(
                        records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        })
                    );

                    setTotal(records.total);
                    setRecordsOnPage(records.per_page);
                    setPage(records.current_page);
                    setFrom(records.from);
                    setTo(records.to);
                    setLoading(false);
                } else {
                    console.log("Error!");
                }
            });
    };




    const handleSort = (column) => {
        //setDirection((prevDirection) => !prevDirection);
        setDirection(!direction);
        setCurrentColumn(column);
        submittedData.submit
            ?
            httpclient
                .get(
                    `https://psw.synccare.com.au/php/api/magic/v1/getItemByLocations?env=${env.value}&Item=${filterData.Item
                    }&Size=${filterData.Size
                    }&ProductType=${filterData.ProductType
                    }&Warehouse=${filterData.Warehouse
                    }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&recordsOnPage=${recordsOnPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];

                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }
                })
            : httpclient
                .get(
                    `https://psw.synccare.com.au/php/api/magic/v1/getItemByLocations?env=${env.value}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&recordsOnPage=${recordsOnPage}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }

                });
    };


    const handleChangePage = (event, page) => {

        //setPage(page);
        setLoading(true);
        submittedData.submit
            ?
            httpclient.get(`https://psw.synccare.com.au/php/api/magic/v1/getItemByLocations?env=${env.value}&Item=${filterData.Item
                }&Size=${filterData.Size
                }&ProductType=${filterData.ProductType
                }&Warehouse=${filterData.Warehouse
                }&sort_by=${currentColumn
                }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
                }&recordsOnPage=${recordsOnPage}&page=${page}`
            )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }
                })
            :
            httpclient
                .get(
                    `https://psw.synccare.com.au/php/api/magic/v1/getItemByLocations?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
                    }&recordsOnPage=${recordsOnPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }

                });
    };



    const handleChangeRecordsOnPage = (event) => {
        //const recordsOnPage = +event.target.value;
        setRecordsOnPage(+event.target.value);
        localStorage.setItem("configRecordsOnPage", event.target.value);

        submittedData.submit
            ?
            httpclient
                .get(`https://psw.synccare.com.au/php/api/magic/v1/getItemByLocations?env=${env.value}&Item=${filterData.Item
                    }&Size=${filterData.Size
                    }&ProductType=${filterData.ProductType
                    }&Warehouse=${filterData.Warehouse
                    }&sort_by=${currentColumn}
                    &direction=${direction !== "" ? direction ? "asc" : "desc" : ""
                    }&page=${page}&recordsOnPage=${+event.target.value}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }
                })
            :
            httpclient
                .get(
                    `https://psw.synccare.com.au/php/api/magic/v1/getItemByLocations?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
                    }&recordsOnPage=${+event.target.value}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }

                });
    };


    // const handleView = (row) => {
    //   setSingleLoading(true);
    //   setOpenViewDialog(true);
    //   httpclient
    //     .get(`getInventoryTransfer?inventoryRegistrationID=${row.inventoryRegistrationID || row}`)
    //     .then(({ data }) => {
    //       if (data.records)
    //         setViewDetails(data.records.data[0]);

    //         setSingleLoading(false);


    //     });
    // };

    // const sendDetails = (callback) => {
    //   if (callback.open === false) {
    //     setOpenViewDialog(false);
    //     setViewDetails({});
    //   }
    //   if (callback.refetch === true) {
    //     handleView(callback.productID);
    //     setTimeout(() => {
    //       getAllInventoryTransfer();
    //     }, 1000)
    //   }
    // };





    const handleFilter = () => {

        setSubmittedData({
            ...submittedData,
            Item: filterData.Item,
            Size: filterData.Size,
            ProductType: filterData.ProductType,
            Warehouse: filterData.Warehouse,
            WarehouseName: filterData.WarehouseName,
            submit: true,
        });
        filterData.remove = true;
        localStorage.setItem("resyncsohlist_filter", JSON.stringify(filterData));
        setLoading(true);
        if (
            filterData.Item ||
            filterData.Size ||
            filterData.ProductType ||
            filterData.Warehouse ||
            filterData.WarehouseName

        ) {
            httpclient
                .get(
                    `https://psw.synccare.com.au/php/api/magic/v1/getItemByLocations?env=${env.value}&Item=${filterData.Item
                    }&Size=${filterData.Size
                    }&ProductType=${filterData.ProductType
                    }&Warehouse=${filterData.Warehouse
                    }&recordsOnPage=${recordsOnPage}&page=${1}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }
                });
        } else {
            getResyncProduct();
        }
    };

    const handleFilterOpen = () => {
        setFilterOpen((prev) => !prev);
        // Close the other filter when opening one
        setFilterOpenSync(false);
    };

    const handleFilterOpenSync = () => {
        setFilterOpenSync((prev) => !prev);
        // Close the other filter when opening one
        setFilterOpen(false);
    };

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        if (name === "Warehouse") {
            setFilterData({
                ...filterData,
                WarehouseName: value,
                remove: false,
            });
        }
        else {
            setFilterData({
                ...filterData,
                [name]: value,
                remove: false,
            });
        }
    };
    const handleChangeWarehouse = (value) => {
        setFilterData({
            ...filterData,
            Warehouse: value !== null ? value.LocationID : "",
            WarehouseName: value !== null ? value.LocationName : "",
            remove: false,
        });
    };

    const handleRemove = (data) => {
        if (data === "Warehouse") {
            filterData.WarehouseName = "";
            submittedData.WarehouseName = "";
        }
        setFilterData({
            ...filterData,
            [data]: "",
            remove: true,
        });

        setSubmittedData({
            ...submittedData,
            [data]: "",
        });
    };




    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Header>
                        <h1>List Resync SOH</h1>
                    </Header>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {/* {loading1 ? (
                        <CircularProgress
                            style={{
                                height: "30px",
                                width: "30px",
                                marginLeft: "10px",
                                marginRight: "30px",
                                position: "relative",
                                top: "10px",
                            }} 
                            />
                    ) : (
                        window.location.hostname === "psw.synccare.com.au" ? (
                            <Button color="primary" variant="contained" onClick={handleFetch1}>
                                Sync Generic Product <Sync style={{ marginLeft: "5px" }} fontSize="small" />
                            </Button>
                        ) : (
                            <Button color="primary" variant="contained" onClick={handleFetch}>
                                Sync Generic Product <Sync style={{ marginLeft: "5px" }} fontSize="small" />
                            </Button>
                        )
                    )} */}

                    {/* <Button color="primary" variant="contained" onClick={handleFilterOpenSync}>
                        Filter For Sync SOH <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button> */}
                    <Button sx={{ marginLeft: "5px" }} color="primary" variant="contained" onClick={handleFilterOpen}>
                        Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button>

                </Grid>

                {/* Filter */}
                <Grid item xs={12}>
                    <Collapse in={filterOpen}>
                        <Card>
                            <Box p={4}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Warehouse</InputLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="location_name"
                                            options={warehouses}
                                            inputValue={filterData.WarehouseName}
                                            getOptionLabel={(option) => option.LocationName}
                                            renderOption={(props, option, index) => { return <li {...props} key={option.LocationID}>{option.LocationName}</li> }}
                                            onChange={(event, newValue) => {
                                                handleChangeWarehouse(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onChange={handleChangeFilter}
                                                    onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                                    value={filterData.WarehouseName}
                                                    variant="outlined"
                                                    name="Warehouse"

                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Item</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="Item"
                                            value={filterData.Item}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Size</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="Size"
                                            value={filterData.Size}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            fullWidth
                                        />
                                    </Grid>




                                    {/* <Grid item xs={12} md={6}>
                                        <InputLabel>Product Type</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="ProductType"
                                            value={filterData.ProductType}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Category</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="Warehouse"
                                            value={filterData.Warehouse}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            fullWidth
                                        />
                                    </Grid> */}

                                    {/* <Grid item xs={12} md={6}>
                                        <InputLabel>Customer Group</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={filterData.Warehouse}
                                                // label="Exclusive Pending"
                                                name="Warehouse"
                                                onChange={handleChangeFilter}
                                            >
                                                <MenuItem value={""}>Select</MenuItem>
                                                <MenuItem value={"Wholesale"}>Wholesale</MenuItem>
                                                <MenuItem value={"Retail"}>Retail</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid> */}



                                    <Grid item xs={12}>
                                        <Box textAlign={"right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleFilter}
                                            >
                                                Filter{" "}
                                                <ArrowForward
                                                    fontSize="small"
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Collapse>

                    <Collapse in={filterOpenSync}>
                        <Card>
                            <Box p={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Warehouse</InputLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="location_name"
                                            options={warehouses}
                                            inputValue={filterData.WarehouseName}
                                            getOptionLabel={(option) => option.LocationName}
                                            renderOption={(props, option, index) => { return <li {...props} key={option.LocationID}>{option.LocationName}</li> }}
                                            onChange={(event, newValue) => {
                                                handleChangeWarehouse(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onChange={handleChangeFilter}
                                                    onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                                    value={filterData.WarehouseName}
                                                    variant="outlined"
                                                    name="Warehouse"

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box textAlign={"right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleFilter}
                                            >
                                                Filter{" "}
                                                <ArrowForward
                                                    fontSize="small"
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{ marginLeft: "5px" }}
                                            //onClick={handleSync}
                                            >
                                                Sync{" "}
                                                <Sync
                                                    fontSize="small"
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Button>

                                        </Box>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Card>
                    </Collapse>

                </Grid>

                {submittedData.Item ||
                    submittedData.Size ||
                    submittedData.ProductType ||
                    submittedData.Warehouse ||
                    submittedData.WarehouseName
                    ? (
                        <Grid item xs={12}>
                            <FilteredBox>
                                <span>Filtered: </span>
                                {submittedData.Item && (
                                    <p>
                                        <span>Item Name: {submittedData.Item}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("Item")}
                                        />
                                    </p>
                                )}
                                {submittedData.Size && (
                                    <p>
                                        <span>Size: {submittedData.Size}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("Size")}
                                        />
                                    </p>
                                )}
                                {submittedData.ProductType && (
                                    <p>
                                        <span>Product Type: {submittedData.ProductType}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("ProductType")}
                                        />
                                    </p>
                                )}
                                {submittedData.WarehouseName && (
                                    <p>
                                        <span>Warehouse: {submittedData.WarehouseName}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("Warehouse")}
                                        />
                                    </p>
                                )}


                            </FilteredBox>
                        </Grid>
                    ) : (
                        <Box></Box>
                    )}
                {/* Filter */}

                <Grid item xs={12}>
                    <TableComponent
                        columns={columns}
                        rows={rows}
                        sort={true}
                        handleSort={handleSort}
                        handleSync={handleSync}
                        // handleView={handleView}
                        loading={loading}
                        loading1={loading1}
                        deletingRow={deletingRow}
                        direction={direction}
                        currentColumn={currentColumn}
                        page={page}
                        total={total && total}
                        fromTable={from}
                        toTable={to}
                        recordsOnPage={recordsOnPage}
                        filterData={filterData}
                        handleChangePage={handleChangePage}
                        handleChangeRecordsOnPage={handleChangeRecordsOnPage}
                    />
                </Grid>
            </Grid>

            {/* {openViewDialog && (
        <ViewInventoryDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )} */}

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ResyncSOH;

import axios from 'axios';
import GlobalURL from './global';

var token = localStorage.getItem('token');
var tokenData = JSON.parse(token);


let httpclient = axios.create({
  //baseURL: 'https://uba.synccare.com.au/php/api',
  //baseURL: 'http://192.168.1.88/api/',
  //baseURL: 'http://192.168.1.88:8000/api/',
  baseURL: GlobalURL[0].url, 
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    // 'Content-Type': "application/json",
    Authorization: "Bearer " + tokenData,
  },
});

// let staginghttpclient = axios.create({
//   //baseURL: 'https://uba.synccare.com.au/php/api',
//   //baseURL: 'http://192.168.1.88/api/',
//   //baseURL: 'http://192.168.1.88:8000/api/',
//   baseURL: 'http://psw.synccare.com.au/php/api/', 
//   headers: {
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
//     'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
//     // 'Content-Type': "application/json",
//     Authorization: "Bearer " + tokenData,
//   },
// });

export default httpclient;
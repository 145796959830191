import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import httpclient from "../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
  },
}));

const RootCard = styled(Card)(({ theme }) => ({
  padding: "40px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

const PasswordBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "& svg": {
    cursor: "pointer",
    position: "absolute",
    right: "18px",
    top: "18px",
    color: "#999",
  },
}));

const UpdateUser = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
    status: "",
    type: "",

  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [viewPassword_confirmation, setViewPassword_confirmation] = React.useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  var loginData = localStorage.getItem("user-login");

  //var userValue = JSON.parse(userData);
  var loginValue = JSON.parse(loginData);

  

  useEffect(() => {
    getSingleUser();
  }, []);

  const getSingleUser = () => {
    id &&
      httpclient.get(`getUser/${id}`).then(({ data }) => {
        if (data.status === 200) {
          setUserDetails({

            email: data.records.email,
            first_name: data.records.first_name,
            last_name: data.records.last_name,
            status: data.records.status === 1 ? true : false,
            type: data.records.type,

            
          });
        }
      });
  };

 // console.log("user details", userDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    const value = checked ? 1 : 0;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };


  const handleBack = () => {
    navigate(-1);
  };


  const handleSubmit = () => {


    if (!id) {
     
      httpclient
        .post("registerUser", {
          email: userDetails.email,
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          password: userDetails.password,
          password_confirmation: userDetails.password_confirmation,
          status: userDetails.status === 1 ? true : false,
          type: userDetails.type,
        })
        .then(({ data }) => {
          if (data.status === 200) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setTimeout(() => {
              navigate("/get_users");
            }, [1500]);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.error[0]);
          }
        });
    } else {
      httpclient
        .post(`updateUser/${id}`, {
          email: userDetails.email,
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          status: userDetails.status,
          type: userDetails.type,
        })
        .then(({ data }) => {
          if (data.status === 200) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setTimeout(() => {
              navigate("/get_users");
            }, [1500]);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
  };




  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Header>
        <h1>{id ? "Edit User" : "Add User"}</h1>
      </Header>
      <RootCard>
        <Grid container spacing={2}>


          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              name="first_name"
              type="text"
              label="First Name"
              value={userDetails.first_name}
              onChange={(e) => handleChange(e)}
              sx={{ width: "100%" }}
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              name="last_name"
              type="text"
              label="Last Name"
              value={userDetails.last_name}
              onChange={(e) => handleChange(e)}
              sx={{ width: "100%" }}
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              name="email"
              type="text"
              label="Email"
              value={userDetails.email}
              onChange={(e) => handleChange(e)}
              sx={{ width: "100%" }}
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl>
              <FormControlLabel
                value={userDetails.status === 1 ? true:false}
                checked={userDetails.status}
                name="status"
                control={<Checkbox />}
                onChange={handleCheck}
                label="User Active ?"
              />
            </FormControl>
          </Grid>
          {loginValue && loginValue.type === "superadmin" &&
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">User Type</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userDetails.type}
                name="type"
                label="User Type"
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value="">
                  <em>Select Level</em>
                </MenuItem>
                <MenuItem value={"superadmin"}>Super Admin</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
           }


          {!id ?
            <>
              <Grid item xs={12} md={12}>
                <PasswordBox>
                  <TextField
                    variant="outlined"
                    name="password"
                    type={viewPassword ? "text" : "password"}
                    label="Password"
                    value={userDetails.password}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: "100%" }}
                  />
                  <RemoveRedEye onClick={() => setViewPassword(!viewPassword)} />
                </PasswordBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <PasswordBox>
                  <TextField
                    variant="outlined"
                    name="password_confirmation"
                    type={viewPassword_confirmation ? "text" : "password"}
                    label="Confirm Password"
                    value={userDetails.password_confirmation}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: "100%" }}
                    //helperText={userDetails.password_confirmation.length > 0 && (userDetails.password === userDetails.password_confirmation ? "" : "Password does not match!")}
                  />
                  <RemoveRedEye onClick={() => setViewPassword_confirmation(!viewPassword_confirmation)} />
                </PasswordBox>

              </Grid>

            </>
            : null
          }
        
          <Grid item xs={12}>
            <Box textAlign={"right"}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleBack}
                sx={{ marginRight: "10px" }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              //disabled={!passwordsMatch}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </RootCard>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UpdateUser;

import { Check, Clear, Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTable from "../../../Components/BasicTable";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}



const ViewInventoryTransfer = (props) => {

  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [rowRows, setRowRows] = useState([]);

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });

//   const [warehouseRows, setWarehouseRows] = useState([]);

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  useEffect(() => {
    if (props.viewDetails && props.viewDetails.rows) {
      //const rowNew = JSON.parse(props.viewDetails.rows);
     const rowNew = props.viewDetails.rows;
  
      const rowRows = rowNew.map(async (rowNew) => {
        return {
         // map productID to product name
          productID: rowNew.productID,
          price: rowNew.price,
          amount: rowNew.amount,
        };
      });
      Promise.all(rowRows).then((rows) => {
        setRowRows(rows);
        console.log("rows",rows);
      });
    }
  }, [props.viewDetails]);


//   useEffect(() => {
//     if (props.viewDetails && props.viewDetails.warehouse) {
//       const warehouse = props.viewDetails.warehouse;
//       const warehouseRows = {
//         warehouseID: warehouse.warehouseID,
//         name: warehouse.name,
//         code: warehouse.code,
//         email: warehouse.email,
//         phone: warehouse.phone,
//         created_at: warehouse.created_at,
//       };
//       setWarehouseRows(warehouseRows);
//      // console.log(warehouseRows);
//     }
//   }, [props.viewDetails]);

 
  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };


  const rowColumns = [
    { id: "productID", name: "Product" },
    { id: "price", name: "Price($)" },
    { id: "amount", name: "Quantity" },
  ];

//   const warehouseColumns = [
//     { id: "warehouseID", name: "Erply Warehouse ID" },
//     { id: "name", name: "Warehouse Name" },
//     { id: "code", name: "Code" },
//     { id: "created_at", name: "Created Date" },


//   ];
  


  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div><div>{`View Inventory Details (${
            props.viewDetails.inventoryTransferID|| "_"
          })`}</div></div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ margin: "0", padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Product Details" {...a11yProps(0)} />
                {/* <Tab label="Warehouse Details" {...a11yProps(1)} /> */}
              </Tabs>
            </AppBarTabs>


            {/* Product Details */}
            <TabPanel value={value} index={0} dir={theme.direction}>

              <BasicTable
                columns={rowColumns}
                rows={rowRows}
              />
            </TabPanel>

            {/* <TabPanel value={value} index={1} dir={theme.direction}>

              <BasicTable
                columns={warehouseColumns}
                rows={[warehouseRows]}
              />
            </TabPanel> */}

            {/* Product Details */}

          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewInventoryTransfer;


import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import moment from 'moment/moment';


const title = [
  { id: "erplyToSynccare", name: "Erply To SyncCare" },
  { id: "erplyToSynccareSynced7Days", name: "Erply To SyncCare Synced 7 Days" },
  { id: "erplyToSynccareSyncedToday", name: "Erply To SyncCare Synced Today" },
  { id: "erplyToSynccareSyncedYesterday", name: "Erply To SyncCare Synced Yesterday" },
 
  
];

const RealtimeGrid3 = () => {
  const [loading, setLoading] = useState(true);
  const [productStatus, setProductStatus] = useState({});

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60 * 1000); // Fetch data every 60 seconds
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);


  const fetchData = () => {
    setLoading(true);
    httpclient
      .get(`productLastSyncErplyToSynccare?env=${env.value}`)
      .then(({ data }) => {
        if (data.records) {
          const productStatus = data.records;
          setProductStatus(productStatus);
          
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
      
  };
  //console.log("prod.ststus", productStatus)
 
  
  const renderContent = () => {
    if (loading) {
      return (
        <div className="grid-block-content">
          {title.map((item) => (
            <React.Fragment key={item.id}>
              <div className="grid-item title">{item.name}</div>
              <div className="grid-item content">
                <Skeleton variant="text" />
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }
  
    return (
      <div className="grid-block-content">
        {title.map((item) => (
          <React.Fragment key={item.id}>
            <div className="grid-item title">{item.name}</div>
            <div className="grid-item content">{productStatus[item.id]}</div>
          </React.Fragment>
        ))}
      </div>
    );
  };
  
  


  return (

    <div className="grid-block">
      <div className="grid-block-header">
      Product: Last Sync Erply To Synccare
      </div>
      {renderContent()}
      <style jsx>{`
        .grid-block {
          border: 0.5px solid #gray;
          box-shadow:0px 5px 20px 0px rgba(0,0,0,0.07);
          padding: 0px;
          background-color: #fff;
          border-radius: 5px;
          
         
        }

        .grid-block-header {
          font-size: 18px;
          font-weight: bold;
          padding:10px;
          margin-top: 0.3px;
          background-color: #014868;
          color: #fff;
          border-radius: 3px;
        
          height: 100%;
          min-height:70px;
          display: flex; 
          align-items: center;
        }

        .grid-block-content {
          display: grid;
         
          width: 100%;
          grid-template-columns: 1fr auto;
          gap: 1px solid #ccc;
          font-family: "Trebechut" sans serief;
          font-size: 14px;
          font-weight: bold;
        }

        .grid-item.title {
          border-right: 1px solid #f1f1f1;
          border-bottom: 1px solid #f1f1f1;
          background-color: #ffffff;
          padding: 16px;
        }
        .grid-item.content {
          border-bottom: 1px solid #f1f1f1;
          background-color: #ffffff;
          padding: 16px;
          min-width: 120px;
        }

        
      `}</style>
    </div>
  );
};

export default RealtimeGrid3;


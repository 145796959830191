import { Box, Card, Grid, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RealtimeGrid from './RealtimeGrid'
import RealtimeGrid1 from './RealtimeGrid1'
import RealtimeGrid2 from './RealtimeGrid2'
import RealtimeGrid3 from './RealtimeGrid3'
import RealtimeGrid4 from './RealtimeGrid4'
import RealtimeGrid5 from './RealtimeGrid5'


const MyCard = styled(Card)(({theme}) => ({
  padding:"20px",
  "& h3":{
    margin:"0"
  }
}))

const Home = () => {

  
    const [seconds, setSeconds] = useState(60);
   

    useEffect(() => {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        setSeconds(60);
      }
    });
  
    return (
      <div>
        <div>
          <Grid container spacing={4}>
            
            <Grid item xs={12} sm={6} md={4}>
              <RealtimeGrid1 />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <RealtimeGrid2 />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <RealtimeGrid3 />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <RealtimeGrid4 />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <RealtimeGrid5 />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <RealtimeGrid />
            </Grid>
            
            <Grid item xs={12} sm={6} md={6}>
              <div className="grid-block-timer">
                <h3>Refreshing Dashboard in:</h3>
                <div className="timer_box">00:{String(seconds).padStart(2, '0')}</div>
              </div>
            </Grid>
  
          </Grid>
        </div>
        <style jsx>{`
          .grid-block-timer {
            border: 0.5px solid #gray;
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07);
            background-color: #fff;
            border-radius: 5px;
            padding: 80px;
          }
        `}</style>
      </div>
    );
  };
  
  export default Home;
  
import { GroupOutlined, RemoveRedEye } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    styled,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import httpclient from "../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
    },
}));

const RootCard = styled(Card)(({ theme }) => ({
    padding: "40px",
    [theme.breakpoints.down("md")]: {
        padding: "20px",
    },
}));

const PasswordBox = styled(Box)(({ theme }) => ({
    position: "relative",
    "& svg": {
        cursor: "pointer",
        position: "absolute",
        right: "18px",
        top: "18px",
        color: "#999",
    },
}));

const SaveCustomer = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({
        //id:"",
        firstName: "",
        lastName: "",
        groupID: "",
        mobile: "",
        email: "",
        customerType: "",
        euCustomerType: "",
        attributeName1: "",
        attributeType1: "",
        attributeValue1: "",
        longAttributeName1: "",
        longAttributeValue1: "",

    });

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [viewPassword_confirmation, setViewPassword_confirmation] = React.useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [rows, setRows] = useState([]);
    const [customerGroupList, setCustomerGroupList] = useState([]);


   


    useEffect(() => {
        getAllCustomers();

    }, []);


    const getAllCustomers = () => {
        httpclient.get(`getCustomerGroups`).then(({ data }) => {
            if (data.status === 200) {
                setCustomerGroupList(data.records.data);
            } else {
                console.log("Error!");
            }
        });
    };



   // console.log("user details", userDetails);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value,
        });
    };




    const handleBack = () => {
        navigate(-1);
    };


    const handleSubmit = () => {


    
        httpclient
            .post(`saveCustomer`, {
                //id: userDetails.id,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                groupID: userDetails.groupID,
                mobile: userDetails.mobile,
                email: userDetails.email,
                customerType: userDetails.customerType,
                euCustomerType: userDetails.euCustomerType,
                attributeName1: userDetails.attributeName1,
                attributeType1: userDetails.attributeType1,
                attributeValue1: userDetails.attributeValue1,
                longAttributeName1: userDetails.longAttributeName1,
                longAttributeValue1: userDetails.longAttributeValue1,



            })
            .then(({ data }) => {
                if (data.status === 200) {
                    setOpen(true);
                    setMessageState("success");
                    setMessage(data.message);
                    setTimeout(() => {
                        navigate(`/customers/list_customers`);
                    }, [1500]);
                } else {
                    setOpen(true);
                    setMessageState("error");
                    setMessage(data.error);
                }
            });
    }
    


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <div>
            <Header>
                <h1>Save Customer</h1>
            </Header>
            <RootCard>
                <Grid container spacing={2}>

                    {/* <Grid item xs={12} md={12}>
                        <TextField
                            variant="outlined"
                            name="customerGroupID"
                            type="text"
                            label="ID"
                            value={userDetails.customerGroupID}
                            onChange={(e) => handleChange(e)}
                            sx={{ width: "100%" }}
                        />
                    </Grid> */}


                    <Grid item xs={12} md={12}>
                        <TextField
                            variant="outlined"
                            name="firstName"
                            type="text"
                            label="First Name"
                            value={userDetails.firstName}
                            onChange={(e) => handleChange(e)}
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            variant="outlined"
                            name="lastName"
                            type="text"
                            label="Last Name"
                            value={userDetails.lastName}
                            onChange={(e) => handleChange(e)}
                            sx={{ width: "100%" }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Group</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userDetails.groupID}
                                name="groupID"
                                label="Group"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value="">
                                    <em>Select Group</em>
                                </MenuItem>
                                {customerGroupList.map((cust) => (
                                    <MenuItem value={cust.customerGroupID} key={cust.customerGroupID}>
                                        {cust.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            variant="outlined"
                            name="mobile"
                            type="text"
                            label="Mobile"
                            value={userDetails.mobile}
                            onChange={(e) => handleChange(e)}
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            variant="outlined"
                            name="email"
                            type="text"
                            label="Email"
                            value={userDetails.email}
                            onChange={(e) => handleChange(e)}
                            sx={{ width: "100%" }}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userDetails.customerType}
                                name="customerType"
                                label="Customer Type"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value="">
                                    <em>Select Customer Type</em>
                                </MenuItem>
                                <MenuItem value={"PERSON"}>Person</MenuItem>
                                <MenuItem value={"COMPANY"}>Company</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userDetails.euCustomerType}
                                name="euCustomerType"
                                label="EU Customer Type"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value="">
                                    <em>Select EU Customer Type</em>
                                </MenuItem>
                                <MenuItem value={"DOMESTIC"}>Domestic</MenuItem>
                                <MenuItem value={"EU"}>EU</MenuItem>
                                <MenuItem value={"EU_WITH_VAT"}>EU with VAT</MenuItem>
                                <MenuItem value={"OUTSIDE_EU"}>Outside EU</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>




                    <Grid item xs={12}>
                        <Box textAlign={"right"}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleBack}
                                sx={{ marginRight: "10px" }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            //disabled={!passwordsMatch}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </RootCard>

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SaveCustomer;

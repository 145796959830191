import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import ViewDetailsDialog from "../ViewDetailsDialog.js";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
  

  { id: "sn", name: "SN" },
  { id: "id", name: "" },
  { id: "warehouseID", name: "" },
  { id: "LocationName", name: "Name" },
  { id: "ADDRESS", name: "Address" },
  { id: "ENTITY", name: "Entity" },
  // { id: "added", name: "Added Date" },  
  // { id: "changed", name: "Changed Date" },

];


const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ListWarehouses = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [selectedID, setSelectedID] = useState("");
  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [open, setOpen] = useState(false);
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [snackStatus, setSnackStatus] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [rows, setRows] = useState([]);
  const [entity, setEntity] = useState([]);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [filterData, setFilterData] = useState({

    ENTITY: "",
    LocationName: "",

    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    ENTITY: "",
    LocationName: "",
    submit: false,
  });



  useEffect(() => {
    if (

      filterData.LocationName === "" &&
      filterData.ENTITY === ""

    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }

    if (filterData.LocationName === " ") filterData.LocationName = "";
    if (filterData.ENTITY === " ") filterData.ENTITY = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(
      localStorage.getItem("warehouselist_filter")
    );
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getAllWarehouses()
      :
      currentpolicy.LocationName == "" &&
        currentpolicy.ENTITY == "" &&

        currentpolicy.removed == false
        ? getAllWarehouses()
        : console.log("current price policy!");
  }, []);

  useEffect(() => {
    getEntity();
    //getAllProductGroups();
}, [])


const getEntity = () => {

httpclient
 .get(`getEntity?env=${env.value}`)
 .then(({ data }) => {
   if (data.status === 200) {
     const records = data.records; // Extract the array of records from the response
     setEntity(records);
   
   } else {
     console.log("Error!");
   }
 });
};



  const getAllWarehouses = () => {
    setLoading(true);
    httpclient
      .get(`getWarehouses?env=${env.value}&page=${page}&recordsOnPage=${recordsOnPage}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setRows(records.data.map((record) => {
            const warehouse = {};
            columns.forEach((column) => {
              warehouse[column.id] = record[column.id];
            });
            return warehouse;
          }));
          // Set the customer data to the state
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };

  const handleSort = (column) => {
    //setDirection((prevDirection) => !prevDirection);
    setDirection(!direction);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getWarehouses?env=${env.value}&LocationName=${filterData.LocationName
          }&ENTITY=${filterData.ENTITY
          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getWarehouses?env=${env.value}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
              setTotal(records.total);
              setRecordsOnPage(records.per_page);
              setPage(records.current_page);
              setFrom(records.from);
              setTo(records.to);
              setLoading(false);
            } else {
              console.log("Error!");
          }

        });
  };





  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient
        .get(`getWarehouses?env=${env.value}&LocationName=${filterData.LocationName
          }&ENTITY=${filterData.ENTITY
          }&sort_by=${currentColumn
          }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getWarehouses?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };

  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);

    submittedData.submit
      ?
      httpclient
        .get(`getWarehouses?env=${env.value}&LocationName=${filterData.LocationName
          }&ENTITY=${filterData.ENTITY
          }&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}}`)
        .then(({ data }) => {
          setLoading(true);
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getWarehouses?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };

  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getWarehouses?env=${env.value}&id=${row.id || row}&strictFilter=true`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records.data[0]);
          setSingleLoading(false);

      });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.warehouseID);
      setTimeout(() => {
        getAllWarehouses();
      }, 1000)
    }

  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,

      LocationName: filterData.LocationName,
      ENTITY: filterData.ENTITY,


      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("warehouselist_filter", JSON.stringify(filterData));
    setLoading(true);
    if (

      filterData.LocationName ||
      filterData.ENTITY
     
    ) {
      httpclient
        .get(
          `getWarehouses?env=${env.value}&LocationName=${filterData.LocationName === undefined ? "" : filterData.LocationName
          }&ENTITY=${filterData.ENTITY
          }&recordsOnPage=${recordsOnPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const warehouse = {};
              columns.forEach((column) => {
                warehouse[column.id] = record[column.id];
              });
              return warehouse;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        });
    } else {
      getAllWarehouses();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };




  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };



  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Header>
            <h1>List Warehouses</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          

          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

          {/* Filter */}
          <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>


                  <Grid item xs={12} md={6}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="LocationName"
                      value={filterData.LocationName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Entity</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.ENTITY}
                        // label="Exclusive Pending"
                        name="ENTITY"
                        onChange={handleChangeFilter}
                      >

                        <MenuItem value={""}>Select</MenuItem>
                        {entity.map((ent, index) => (
                        <MenuItem value={ent.ENTITY}>{ent.ENTITY}</MenuItem>
                        
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                 
                 

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {
          submittedData.LocationName ||
          submittedData.ENTITY 
           ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.LocationName && (
                <p>
                  <span>Name: {submittedData.LocationName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("LocationName")}
                  />
                </p>
              )}
              {submittedData.ENTITY && (
                <p>
                  <span>Entity: {submittedData.ENTITY}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("ENTITY")}
                  />
                </p>
              )}
              
             

              
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}
        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true} 
            handleSort={handleSort}
            handleView={handleView}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            filterData={filterData}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewDetailsDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListWarehouses;

import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import ViewSalesDialog from "../ViewSalesDialog";
import MuiAlert from "@mui/material/Alert";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [


  { id: "sn", name: "SN" },
  { id: "id", name: "" },
  { id: "number", name: "Order Number" },
  { id: "pointOfSaleName", name: "POS Name" },
  { id: "type", name: "Type" },
  { id: "currencyCode", name: "Code" },
  // { id: "inventoryTransactionDate", name: "Transaction Date" },
  // { id: "time", name: "Transaction Time" },
  { id: "clientName", name: "Client" },
  { id: "employeeName", name: "Employee" },
  { id: "warehouseName", name: "Warehouse" },
  { id: "added", name: "Added Date" },
  { id: "lastModified", name: "Last Modified Date" },




];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ListSalesTransaction = () => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [rows, setRows] = useState([]);
  const [entity, setEntity] = useState([]);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");


  const [filterData, setFilterData] = useState({
    number: "",
    ENTITY: "",
    pointOfSaleName: "",
    type: "",
    clientName: "",
    employeeName: "",
    warehouseName: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    number: "",
    ENTITY: "",
    pointOfSaleName: "",
    type: "",
    clientName: "",
    employeeName: "",
    warehouseName: "",
    submit: false,
  });



  useEffect(() => {
    if (
      filterData.number === "" &&
      filterData.ENTITY === "" &&
      filterData.pointOfSaleName === "" &&
      filterData.type === "" &&
      filterData.clientName === "" &&
      filterData.employeeName === "" &&
      filterData.warehouseName === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.number === " ") filterData.number = "";
    if (filterData.ENTITY === " ") filterData.ENTITY = "";
    if (filterData.pointOfSaleName === " ") filterData.pointOfSaleName = "";
    if (filterData.type === " ") filterData.type = "";
    if (filterData.clientName === " ") filterData.clientName = "";
    if (filterData.employeeName === " ") filterData.employeeName = "";
    if (filterData.warehouseName === " ") filterData.warehouseName = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(
      localStorage.getItem("salestransactionlist_filter")
    );
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getAllSalesTransactions()
      : currentpolicy.number == "" &&
        currentpolicy.ENTITY == "" &&
        currentpolicy.pointOfSaleName == "" &&
        currentpolicy.type == "" &&
        currentpolicy.clientName == "" &&
        currentpolicy.employeeName == "" &&
        currentpolicy.warehouseName == "" &&
        currentpolicy.removed == false
        ? getAllSalesTransactions()
        : console.log("current price policy!");
  }, []);

  useEffect(() => {
    getEntity();

  }, [])


  const getEntity = () => {

    httpclient
      .get(`getEntity?env=${env.value}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setEntity(records);

        } else {
          console.log("Error!");
        }
      });
  };



  const getAllSalesTransactions = () => {
    setLoading(true);
    httpclient.get(`getSalesDocuments?env=${env.value}&recordsOnPage=${recordsOnPage}&page=${page}`)
      // &includeMatrixVariations=${0}
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records;
          setRows(records.data.map((record) => {
            const product = {};
            columns.forEach((column) => {
              product[column.id] = record[column.id];
            });
            return product;
          }));
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };

  const handleSort = (column) => {
    setDirection(!direction);
    //setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getSalesDocuments?env=${env.value
          }&number=${filterData.number
          }&ENTITY=${filterData.ENTITY
          }&pointOfSaleName=${filterData.pointOfSaleName
          }&type=${filterData.type
          }&clientName=${filterData.clientName
          }&employeeName=${filterData.employeeName
          }&warehouseName=${filterData.warehouseName
          }&sort_by=${column
          }&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage
          }&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getSalesDocuments?env=${env.value}&sort_by=${column}&page=${page}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}`
        )
        // &select=salesDocumentID,pointOfSaleName,type,currencyCode,inventoryTransactionDate,time,clientName,employeeName,warehouseName,added,lastModified
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
  };




  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient.get(`getSalesDocuments?env=${env.value
        }&number=${filterData.number
        }&ENTITY=${filterData.ENTITY
        }&pointOfSaleName=${filterData.pointOfSaleName
        }&type=${filterData.type
        }&clientName=${filterData.clientName
        }&employeeName=${filterData.employeeName
        }&warehouseName=${filterData.warehouseName
        }&sort_by=${currentColumn}&direction=${!direction ? "asc" : "desc"
        }&recordsOnPage=${recordsOnPage}&page=${page}`
      )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getSalesDocuments?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };



  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);

    submittedData.submit
      ?
      httpclient
        .get(`getSalesDocuments?env=${env.value
          }&number=${filterData.number
          }&ENTITY=${filterData.ENTITY
          }&pointOfSaleName=${filterData.pointOfSaleName
          }&type=${filterData.type
          }&clientName=${filterData.clientName
          }&employeeName=${filterData.employeeName
          }&warehouseName=${filterData.warehouseName
          }&sort_by=${currentColumn}
        &direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&page=${page}&recordsOnPage=${+event.target.value}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getSalesDocuments?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };


  const handleView = (row) => {
    console.log("roww", row);
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getSalesDocuments?env=${env.value}&id=${row.id || row}`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records.data[0]);
          setSingleLoading(false);
        // console.log("data", data.records.data[0])

      });
  };



  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.productID);
      setTimeout(() => {
        getAllSalesTransactions();
      }, 1000)
    }
  };



  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      number: filterData.number,
      ENTITY: filterData.ENTITY,
      pointOfSaleName: filterData.pointOfSaleName,
      clientName: filterData.clientName,
      type: filterData.type,
      employeeName: filterData.employeeName,
      warehouseName: filterData.warehouseName,

      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("salestransactionlist_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.number ||
      filterData.ENTITY ||
      filterData.pointOfSaleName ||
      filterData.type ||
      filterData.clientName ||
      filterData.employeeName ||
      filterData.warehouseName
    ) {
      httpclient
        .get(
          `getSalesDocuments?env=${env.value
          }&number=${filterData.number
          }&ENTITY=${filterData.ENTITY
          }&pointOfSaleName=${filterData.pointOfSaleName
          }&type=${filterData.type
          }&clientName=${filterData.clientName
          }&employeeName=${filterData.employeeName
          }&warehouseName=${filterData.warehouseName
          }&recordsOnPage=${recordsOnPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        });
    } else {
      getAllSalesTransactions();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Sales Documents</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Order Number</InputLabel>
                    <TextField
                      variant="outlined"
                      name="number"
                      value={filterData.number}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>POS Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="pointOfSaleName"
                      value={filterData.pointOfSaleName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Client Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="clientName"
                      value={filterData.clientName}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      onChange={handleChangeFilter}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Employee Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="employeeName"
                      value={filterData.employeeName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Warehouse Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="warehouseName"
                      value={filterData.warehouseName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Transaction Type</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.type}
                        // label="Exclusive Pending"
                        name="type"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"ORDER"}>Order</MenuItem>
                        <MenuItem value={"CASHINVOICE"}>Cash Invoice</MenuItem>
                        <MenuItem value={"CREDITINVOICE"}>Credit Invoice</MenuItem>
                        <MenuItem value={"PREPAYMENT"}>Pre Payment</MenuItem>
                        <MenuItem value={"WAYBILL"}>Waybill</MenuItem>
                        <MenuItem value={"INVWAYBILL"}>InvWaybill</MenuItem>
                        <MenuItem value={"OFFER"}>Offer</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Entity</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.ENTITY}
                        // label="Exclusive Pending"
                        name="ENTITY"
                        onChange={handleChangeFilter}
                      >

                        <MenuItem value={""}>Select</MenuItem>
                        {entity.map((ent, index) => (
                          <MenuItem value={ent.ENTITY}>{ent.ENTITY}</MenuItem>

                        ))}
                      </Select>
                    </FormControl>
                  </Grid>



                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        { 
          submittedData.number ||
          submittedData.ENTITY ||
          submittedData.pointOfSaleName ||
          submittedData.type ||
          submittedData.employeeName ||
          submittedData.clientName ||
          submittedData.warehouseName ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>

              {submittedData.number && (
                <p>
                  <span>Order Number: {submittedData.number}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("number")}
                  />
                </p>
              )}
              {submittedData.ENTITY && (
                <p>
                  <span>Entity: {submittedData.ENTITY}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("ENTITY")}
                  />
                </p>
              )}
              {submittedData.pointOfSaleName && (
                <p>
                  <span>POS Name: {submittedData.pointOfSaleName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("pointOfSaleName")}
                  />
                </p>
              )}
              {submittedData.warehouseName && (
                <p>
                  <span>Warehouse: {submittedData.warehouseName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("warehouseName")}
                  />
                </p>
              )}
              {submittedData.employeeName && (
                <p>
                  <span>Employee: {submittedData.employeeName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("employeeName")}
                  />
                </p>
              )}
              {submittedData.clientName && (
                <p>
                  <span>Client: {submittedData.clientName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("clientName")}
                  />
                </p>
              )}
              {submittedData.type && (
                <p>
                  <span>Transaction Type: {submittedData.type}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("type")}
                  />
                </p>
              )}

            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleSort={handleSort}
            handleView={handleView}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            filterData={filterData}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewSalesDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListSalesTransaction;

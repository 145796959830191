import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import httpclient from "../../../utils/httpClient";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import ViewProductDialog from "../ViewProductDialog";
import MuiAlert from "@mui/material/Alert";
import env from "../../../utils/env";
import ViewDialog from "../ViewDialog";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [


  { id: "sn", name: "SN" },
  { id: "erplyID", name: "" },
  { id: "ColourID", name: "" },
  { id: "type", name: "Variation Type" },
  { id: "ItemName", name: "Name" },
  { id: "ColourName", name: "Colour" },
  { id: "SizeID", name: "Size" },
  { id: "WEBSKU", name: "Web SKU" },
  { id: "SchoolName", name: "Product Group" },
  // { id: "categoryName", name: "Category" },
  { id: "RetailSalesPrice", name: "Price($)" },
  { id: "ERPLYFLAG", name: "Entity" },
  { id: "erplyEnabled", name: "Erply Enabled?" },
  { id: "WebEnabled", name: "Web Enabled?" },



];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ListProductVariations = () => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});



  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [rows, setRows] = useState([]);
  const [entity, setEntity] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");


  const [filterData, setFilterData] = useState({
    SchoolName: "",
    categoryName: "",
    type: "",
    ItemName: "",
    WEBSKU: "",
    ERPLYSKU: "",
    WebEnabled: "",
    ERPLYFLAG: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    SchoolName: "",
    categoryName: "",
    type: "",
    ItemName: "",
    WEBSKU: "",
    ERPLYSKU: "",
    WebEnabled: "",
    ERPLYFLAG: "",
    submit: false,
  });



  useEffect(() => {
    if (
      filterData.SchoolName === "" &&
      filterData.categoryName === "" &&
      filterData.type === "" &&
      filterData.ItemName === "" &&
      filterData.WEBSKU === "" &&
      filterData.ERPLYSKU === "" &&
      filterData.WebEnabled === "" &&
      filterData.ERPLYFLAG === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.SchoolName === " ") filterData.SchoolName = "";
    if (filterData.categoryName === " ") filterData.categoryName = "";
    if (filterData.type === " ") filterData.type = "";
    if (filterData.ItemName === " ") filterData.ItemName = "";
    if (filterData.WEBSKU === " ") filterData.WEBSKU = "";
    if (filterData.ERPLYSKU === " ") filterData.ERPLYSKU = "";
    if (filterData.WebEnabled === " ") filterData.WebEnabled = "";
    if (filterData.ERPLYFLAG === " ") filterData.ERPLYFLAG = "";
    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(
      localStorage.getItem("productvariationlist_filter")
    );
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getAllProducts()
      : currentpolicy.SchoolName == "" &&
        currentpolicy.categoryName == "" &&
        currentpolicy.type == "" &&
        currentpolicy.ItemName == "" &&
        currentpolicy.WEBSKU == "" &&
        currentpolicy.ERPLYSKU == "" &&
        currentpolicy.WebEnabled == "" &&
        currentpolicy.ERPLYFLAG == "" &&
        currentpolicy.removed == false
        ? getAllProducts()
        : console.log("current price policy!");
  }, []);

  useEffect(() => {
    getEntity();
    getAllProductGroups();
  }, [])


  const getEntity = () => {

    httpclient
      .get(`getEntity?env=${env.value}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setEntity(records);

        } else {
          console.log("Error!");
        }
      });
  };
  console.log("categories", entity)

  const getAllProductGroups = () => {

    httpclient
      .get(
        `getProductGroups?env=${env.value}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records.data; // Extract the array of records from the response
          setProductGroups(records);

        } else {
          console.log("Error!");
        }
      });
  };



  const getAllProducts = () => {
    setLoading(true);
    httpclient.get(`getProducts?includeMatrixVariations=${1}&env=${env.value}&recordsOnPage=${recordsOnPage}&page=${page}`)
      // &includeMatrixVariations=${0}
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records;
          setRows(records.data.map((record) => {
            const product = {};
            columns.forEach((column) => {
              product[column.id] = record[column.id];
            });
            return product;
          }));
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };

  const handleSort = (column) => {
    setDirection(!direction);
    //setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getProducts?includeMatrixVariations=${1}&env=${env.value}&SchoolName=${filterData.SchoolName
          }&categoryName=${filterData.categoryName
          }&type=${filterData.type
          }&ItemName=${filterData.ItemName
          }&WEBSKU=${filterData.WEBSKU
          }&ERPLYSKU=${filterData.ERPLYSKU
          }&WebEnabled=${filterData.WebEnabled
          }&ERPLYFLAG=${filterData.ERPLYFLAG
          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        // &select=productID,name,type,WEBSKU,SchoolName,categoryName,price,active,WebEnabled
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getProducts?includeMatrixVariations=${1}&env=${env.value}&sort_by=${column}&page=${page}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
  };




  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient.get(`getProducts?includeMatrixVariations=${1}&env=${env.value}&SchoolName=${filterData.SchoolName
        }&categoryName=${filterData.categoryName
        }&type=${filterData.type
        }&ItemName=${filterData.ItemName
        }&WEBSKU=${filterData.WEBSKU
        }&ERPLYSKU=${filterData.ERPLYSKU
        }&WebEnabled=${filterData.WebEnabled
        }&ERPLYFLAG=${filterData.ERPLYFLAG
        }&sort_by=${currentColumn
        }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${recordsOnPage}&page=${page}`
      )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getProducts?includeMatrixVariations=${1}&env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };



  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);

    submittedData.submit
      ?
      httpclient
        .get(`getProducts?includeMatrixVariations=${1}&env=${env.value}&SchoolName=${filterData.SchoolName
          }&categoryName=${filterData.categoryName
          }&type=${filterData.type
          }&ItemName=${filterData.ItemName
          }&WEBSKU=${filterData.WEBSKU
          }&ERPLYSKU=${filterData.ERPLYSKU
          }&WebEnabled=${filterData.WebEnabled
          }&ERPLYFLAG=${filterData.ERPLYFLAG
          }&sort_by=${currentColumn}
          &direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&page=${page}&recordsOnPage=${+event.target.value}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getProducts?includeMatrixVariations=${1}&env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };


  const handleView = (row, row1) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getProducts?includeMatrixVariations=${1}&env=${env.value}&erplyID=${row.erplyID || row}&WEBSKU=${row.WEBSKU || row1}`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records.data[0]);
        setSingleLoading(false);
      });
  };



  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.erplyID, callback.WEBSKU);
      setTimeout(() => {
        handleFilter();
      }, 1000)
    }
  };



  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      SchoolName: filterData.SchoolName,
      categoryName: filterData.categoryName,
      type: filterData.type,
      ItemName: filterData.ItemName,
      WEBSKU: filterData.WEBSKU,
      ERPLYSKU: filterData.ERPLYSKU,
      WebEnabled: filterData.WebEnabled,
      ERPLYFLAG: filterData.ERPLYFLAG,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("productvariationlist_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.SchoolName ||
      filterData.categoryName ||
      filterData.type ||
      filterData.ItemName ||
      filterData.WEBSKU ||
      filterData.ERPLYSKU ||
      filterData.WebEnabled ||
      filterData.ERPLYFLAG
    ) {
      httpclient
        .get(
          `getProducts?includeMatrixVariations=${1}&env=${env.value}&SchoolName=${filterData.SchoolName
          }&categoryName=${filterData.categoryName
          }&type=${filterData.type
          }&ItemName=${filterData.ItemName
          }&WEBSKU=${filterData.WEBSKU
          }&strictFilter=1
          }&ERPLYSKU=${filterData.ERPLYSKU
          }&WebEnabled=${filterData.WebEnabled
          }&ERPLYFLAG=${filterData.ERPLYFLAG
          }&recordsOnPage=${recordsOnPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        });
    } else {
      getAllProducts();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeSchool = (value) => {
    setFilterData({
      ...filterData,
      SchoolName: value !== null ? value.SchoolName : "",

      remove: false,
    });
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const sortedOptions = React.useMemo(() => {
    const inputValue = filterData.SchoolName || "";

    const matchedInitialLetters = [];
    const remainingOptions = [];

    productGroups.forEach((option) => {
      const SchoolName = option.SchoolName || "";


      if (
        SchoolName.toLowerCase().startsWith(inputValue.toLowerCase())

      ) {
        matchedInitialLetters.push(option);
      } else {
        remainingOptions.push(option);
      }
    });

    return [...matchedInitialLetters, ...remainingOptions];
  }, [filterData.SchoolName, productGroups]);


  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Products</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Item Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="ItemName"
                      value={filterData.ItemName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                    <InputLabel>Category</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.categoryName}
                        // label="Exclusive Pending"
                        name="categoryName"
                        onChange={handleChangeFilter}
                      >

                        <MenuItem value={""}>Select</MenuItem>
                        {categories.map((category, index) => (
                          <MenuItem value={category.productCategoryName}>{category.productCategoryName}</MenuItem>

                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} md={4}>
                    <InputLabel>Product Group</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="school_name"
                      options={sortedOptions}
                      inputValue={filterData.SchoolName}
                      getOptionLabel={(option) => option.SchoolName}
                      renderOption={(props, option, index) => { return <li {...props} key={option.SchoolID}>{option.SchoolName}</li> }}
                      onChange={(event, newValue) => {
                        handleChangeSchool(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChangeFilter}
                          onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                          value={filterData.SchoolName}
                          variant="outlined"
                          name="SchoolName"

                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Variation Type</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.type}
                        // label="Exclusive Pending"
                        name="type"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"MATRIX"}>Matrix</MenuItem>
                        <MenuItem value={"PRODUCT"}>Product</MenuItem>
                        <MenuItem value={"BUNDLE"}>Bundle</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>WEBSKU</InputLabel>
                    <TextField
                      variant="outlined"
                      name="WEBSKU"
                      value={filterData.WEBSKU}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>ERPLYSKU</InputLabel>
                    <TextField
                      variant="outlined"
                      name="ERPLYSKU"
                      value={filterData.ERPLYSKU}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>





                  <Grid item xs={12} md={4}>
                    <InputLabel>Web Enabled</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.WebEnabled}
                        // label="Exclusive Pending"
                        name="WebEnabled"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>

                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>

                        {/* <MenuItem value={"NO_LONGER_ORDERED"}>No longer Ordered</MenuItem>
                        <MenuItem value={"NOT_FOR_SALE"}>Not for sale</MenuItem>
                        <MenuItem value={"ARCHIVED"}>Archived</MenuItem>
                        <MenuItem value={"ALL_EXCEPT_ARCHIVED"}>All Except Archived</MenuItem> */}

                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Entity</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.ERPLYFLAG}
                        // label="Exclusive Pending"
                        name="ERPLYFLAG"
                        onChange={handleChangeFilter}
                      >

                        <MenuItem value={""}>Select</MenuItem>
                        {entity.map((ent, index) => (
                          <MenuItem value={ent.ENTITY}>{ent.ENTITY}</MenuItem>

                        ))}
                      </Select>
                    </FormControl>
                  </Grid>



                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.SchoolName ||
          submittedData.categoryName ||
          submittedData.type ||
          submittedData.ItemName ||
          submittedData.WEBSKU ||
          submittedData.ERPLYSKU ||
          submittedData.WebEnabled ||
          submittedData.ERPLYFLAG ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.SchoolName && (
                <p>
                  <span>Group: {submittedData.SchoolName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("SchoolName")}
                  />
                </p>
              )}
              {submittedData.categoryName && (
                <p>
                  <span>Category: {submittedData.categoryName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("categoryName")}
                  />
                </p>
              )}
              {submittedData.ItemName && (
                <p>
                  <span>Name: {submittedData.ItemName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("ItemName")}
                  />
                </p>
              )}
              {submittedData.WEBSKU && (
                <p>
                  <span>WEBSKU: {submittedData.WEBSKU}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("WEBSKU")}
                  />
                </p>
              )}
              {submittedData.ERPLYSKU && (
                <p>
                  <span>ERPLYSKU: {submittedData.ERPLYSKU}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("ERPLYSKU")}
                  />
                </p>
              )}
              {submittedData.type && (
                <p>
                  <span>Variation Type: {" "}
                    {submittedData.type === "MATRIX"
                      ? "Matrix"
                      : submittedData.type === "BUNDLE" ? "Bundle" : "Product"}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("type")}
                  />
                </p>
              )}



              {submittedData.WebEnabled && (
                <p>
                  <span>
                    Web Enabled: {submittedData.WebEnabled === "1" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("WebEnabled")}
                  />
                </p>
              )}
              {submittedData.ERPLYFLAG && (
                <p>
                  <span>Entity: {submittedData.ERPLYFLAG}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("ERPLYFLAG")}
                  />
                </p>
              )}
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleSort={handleSort}
            handleView={handleView}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            filterData={filterData}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListProductVariations;

import { Card, styled } from '@mui/material';
import React from 'react'
import Form from './Form';
// import Logo from "./../../images/YourStay_Logo.png";


const MainDiv = styled("div")(({ theme }) => ({
    // backgroundImage: `linear-gradient(to top, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
    backgroundSize: "cover",
    display:"flex",
    alignItems:"center",
    justifyContent:'center',
    height:"100vh",
    width:"100%",
    
  }));

  const FormDiv = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    maxWidth:"400px",
    maxHeight:"500px",
    padding:"20px 40px",
    borderRadius:"10px",
    position: "relative",
  }));

const Login = () => {
  return (
    <MainDiv>
        <FormDiv>
          {/* <LogoDiv>
          <img src={Logo} alt="Logo" />
          </LogoDiv> */}

            <Form />
        </FormDiv>
    </MainDiv>
  )
}

export default Login
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import theme from "../../../theme/theme";
import httpclient from "../../../utils/httpClient";
import qs from 'qs';
import Global from "../../../utils/GlobalValues";
import { fontSize } from "@mui/system";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SubmitDiv = styled("div")(({ theme }) => ({
  "& .MuiButton-root": {
    color: "#fff",
    marginTop: "10px",
    padding:"10px",
    fontSize:"15px"
  },
}));

const Form = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [loading, setLoading] = useState(false);
  //const [severity, setSeverity] = useState('success');
  const [loginData, setLoginData] = useState({
    // email: "admin@gmail.com",
    // password: "admin123",
    // email: "bini1234@gmail.com",
    // password: "bini1234",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleKeyPassword = (e) => {
    if(e.key === "Enter"){
        handleSubmit();
    }
  }
  function handleRegistrationClick() {
    navigate("/#/register-user");
  }

  const handleSubmit = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("email",loginData.email);
    formData.append("password",loginData.password);
    
    httpclient.post('/userLogin', formData).then(({ data }) => {
      console.log(data);
      setLoading(false);
        
      if(data.status === 200 ){
        localStorage.setItem("user-login", JSON.stringify(data.user));
        localStorage.setItem("erplyLogin", 0);
        localStorage.setItem("token", JSON.stringify(data.token));
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);
        setLoading(false);
        setTimeout(() => {
          window.location = "/";
        }, 3000);
        
      }else{
        setLoading(false);
        setOpen(true);
        setMessageState("error");
        setMessage(data.message);
  
      }
    })
}

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            {/* <h2
              style={{
                margin: "0",
                paddingBottom: "20px",
                fontSize: "35px",
              }}
            >
              Login
            </h2> */}
            <img src="/psw_logo.jpg" alt="PSW_Sync_Care" style={{ width:"100%", objectFit:"cover" }} />
          </Grid>
          <Grid item md={12} xs={12}>
            <InputLabel >Email</InputLabel>
            <TextField
              variant="outlined"
              // label="Email"
              name="email"
              type="text"
              value={loginData.email}
              onChange={(e) => handleChange(e)}
              style={{
                width: "100%",
                borderRadius: "5px",
                background: theme.palette.primary.lighter,
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <InputLabel >Password</InputLabel>
            <TextField
              variant="outlined"
              // label="Password"
              name="password"
              type="password"
              value={loginData.password}
              onKeyDown={handleKeyPassword}
              onChange={(e) => handleChange(e)}
              style={{
                width: "100%",
                borderRadius: "5px",
                background: theme.palette.primary.lighter,
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <span >Forgot Password?</span>
          </Grid> */}
          <Grid item xs={12} md={12}>
            <SubmitDiv>
              {loading ?
              <Button
                variant="contained"
                color="secondary"
                // disabled
                fullWidth
              >
                <CircularProgress style={{ height:"20px", width:"20px", color:"#fff", marginRight:"10px" }} /> Loading
              </Button>
              :
              <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              fullWidth
            >
              Sign In
            </Button>
            }
            </SubmitDiv>
          </Grid>

          <Box display="flex" justifyContent="space-between" alignItems="center" textAlign="left" width="100%" mt={3}>
          {/* <small><a href="/#/register-user" onClick={handleRegistrationClick}>Click here to register!</a></small> */}
          <small>ver: {Global.version}</small>
        </Box>

          
        </Grid>
        <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      </div>
    );
  };

export default Form;

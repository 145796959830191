import { Check, Clear, Close, Preview, KeyboardArrowLeft } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTable from "../../../Components/BasicTable";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}



const ViewSalesDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });


  const [salesDetails, setSalesDetails] = useState("");
  const [salesRows, setSalesRows] = useState([]);


  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    if (props.viewDetails && props.viewDetails.sales_details) {
      const salesRows = props.viewDetails.sales_details;
      setSalesRows(salesRows);
      setSalesDetails("");
      // console.log("Result7777777777777777777777:", salesRows);
      // console.log("Result0000000000000000000000:", salesDetails);
    }
  }, [props.viewDetails]);



  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };


  const handleView = (row) => {
    setSalesDetails(row);
  };


  const handleBack = () => {
    setSalesDetails("");
  };



  const salesColumn = [
    { id: "productID", name: "Erply Product ID" },
    { id: "itemName", name: "Item Name" },
    { id: "code", name: "Code" },
    { id: "amount", name: "Quantity" },
    { id: "price", name: "Price($)" },
    { id: "created_at", name: "Created Date" },
    { id: "updated_at", name: "Updated Date" },

  ];




  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>{`View Sales Details (${props.viewDetails.pointOfSaleName || "_"})`}</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ margin: "0", padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Payment Details" {...a11yProps(1)} />
                <Tab label="Sales Details" {...a11yProps(2)} />


              </Tabs>
            </AppBarTabs>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box pt={3}>
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Erply Sales Document ID</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.salesDocumentID || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Order Number</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.number || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>POS Name</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.pointOfSaleName || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Type</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.type || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Client</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.clientName || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Employee</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.employeeName || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Warehouse</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.warehouseName || "-"}</Values>
                    </FlexContent>




                  </Grid>
                  {/* Left Side */}

                  {/* Right Side */}


                  {/* Right Side */}


                </Grid>
              </Box>

            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box pt={3}>
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={12} md={6}>


                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Invoice State</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.invoiceState || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Payment Type</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.paymentType || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Payment Status</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.paymentStatus || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Confirmation State</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.confirmed == "1" ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" />
                        )}{" "}

                      </Values>
                    </FlexContent>



                  </Grid>
                  {/* Left Side */}

                  {/* Right Side */}
                  <Grid item xs={12} md={6}>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Total Amount</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>${props.viewDetails.total || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Paid Amount</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>${props.viewDetails.paid || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Invoice State</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.invoiceState || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Invoice Link</span> <span>:</span>
                      </FlexInnerTitle>
                      <Values>
                        <Preview
                          onClick={() => window.open(props.viewDetails.invoiceLink)}
                          style={{ cursor: 'pointer' }} // Add inline style here
                        >
                          {props.viewDetails.invoiceLink || "-"}
                        </Preview>
                      </Values>

                    </FlexContent>

                  </Grid>

                  {/* Right Side */}


                </Grid>
              </Box>

            </TabPanel>


            <TabPanel value={value} index={2} dir={theme.direction}>
              {salesDetails ? (
                <>
                  <Box textAlign={"right"}>
                    <Button onClick={handleBack}>
                      <KeyboardArrowLeft
                        fontSize="small"
                        sx={{ marginRight: "5px" }}
                      />{" "}
                      <span>Back</span>
                    </Button>
                  </Box>
                  <Grid container spacing={2}>
                    {/* Left Side */}
                    <Grid item xs={12} md={6}>
                      {/* <h3><i>Variation Details</i></h3> */}
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Sales Document ID</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {salesDetails.salesDocumentID || "-"}
                        </Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Item Name</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{salesDetails.itemName || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Code</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{salesDetails.code || "-"}</Values>
                      </FlexContent>





                    </Grid>
                    {/* Left Side */}

                    {/* Right Side */}


                    <Grid item xs={12} md={6}>


                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Quantity</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{salesDetails.amount || "-"}</Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Price</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>${salesDetails.price || "-"}</Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Discount Amount</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>${salesDetails.discount || "-"}</Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Price After Discount</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>${salesDetails.finalNetPrice || "-"}</Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Total Price</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>${salesDetails.rowNetTotal || "-"}</Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>VAT Amount</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>${salesDetails.rowVAT || "-"}</Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Final Price With VAT</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>${salesDetails.finalPriceWithVAT || "-"}</Values>
                      </FlexContent>

                      <hr />

                      <FlexContent>
                        <FlexInnerTitle>
                          <span> Net Total:</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>${salesDetails.rowTotal || "-"}</Values>
                      </FlexContent>

                    </Grid>

                    {/* Right Side */}

                  </Grid>

                </>

              ) : (
                <>

                  {props.viewDetails.sales_details.length > 0 ? (
                    <BasicTable
                      columns={salesColumn}
                      rows={salesRows}
                      handleView={handleView}
                      viewDetails={props.viewDetails} />
                  ) : (
                    <Box p={3}>
                      <span>No sales details record found.</span>
                    </Box>
                  )}
                </>

              )}

            </TabPanel>

          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewSalesDialog;


import { Button, Grid, Snackbar, styled } from "@mui/material";
import { Add, ArrowForward, Close, FilterList } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import ViewDetailsDialog from "../ViewDetailsDialog.js";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import DeleteDialog from "../DeleteDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
  


  { id: "sn", name: "SN" },
  { id: "id", name: "" },
  { id: "customerGroupID", name: "" },
  { id: "name", name: "Name" },
  { id: "created_at", name: "Created Date" },
  { id: "lastModified", name: "Modified Date" },
  { id: "updated_at", name: "Updated Date" },
  //{ id: "actions", name: "Actions" },
];
const options = [

  { id: "edit", name: "Update", action: "handleEdit" },
  { id: "delete", name: "Delete", action: "handleDelete" },
];



const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginLeft: "10px",
  "& svg": {
    fontSize: "15px",
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));


const ListCustomerGroups = () => {
  const navigate = useNavigate();

  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});

  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackStatus, setSnackStatus] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [rows, setRows] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };




  useEffect(() => {
    getAllCustomers();

  }, []);


  const getAllCustomers = () => {
    setLoading(true);
    httpclient
      .get(`getCustomerGroups?env=${env.value}&page=${page}&recordsOnPage=${recordsOnPage}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setRows(records.data.map((record) => {
            const customer = {};
            columns.forEach((column) => {
              customer[column.id] = record[column.id];
            });
            return customer;
          }));
          // Set the customer data to the state
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };

  const handleSort = (column) => {
    //setDirection((prevDirection) => !prevDirection);
    setDirection(!direction);
    setCurrentColumn(column);

    httpclient
      .get(
        `getCustomerGroups?env=${env.value}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
        }&recordsOnPage=${recordsOnPage}&page=${page}`
      )
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setRows(records.data.map((record) => {
            const customer = {};
            columns.forEach((column) => {
              customer[column.id] = record[column.id];
            });
            return customer;
          }));
          // Set the customer data to the state
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      })

  };

  const handleChangePage = (event, page) => {

    setPage(page);
    setLoading(true);
    httpclient
      .get(`getCustomerGroups?env=${env.value}&sort_by=${currentColumn
        }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${recordsOnPage}&page=${page}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setRows(records.data.map((record) => {
            const customer = {};
            columns.forEach((column) => {
              customer[column.id] = record[column.id];
            });
            return customer;
          }));
          // Set the customer data to the state
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };

  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", +event.target.value);
    setLoading(true);
    httpclient
      .get(`getCustomerGroups?env=${env.value}&sort_by=${currentColumn }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${+event.target.value}&page=${page}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setRows(records.data.map((record) => {
            const customer = {};
            columns.forEach((column) => {
              customer[column.id] = record[column.id];
            });
            return customer;
          }));
          // Set the customer data to the state
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });

  };
  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getCustomerGroup?env=${env.value}&id=${row.customerGroupID || row}`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records[0]);
        setSingleLoading(false);

      });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
  };

  const handleEdit = (row) => {
    navigate(`/update_customer_group/${row.id}`);
  };

  const handleAddNew = () => {
    navigate(`/save_customer_group`);
  };

  // const handleAdd = (row) => {
  //   navigate(`/save_customer?id=${row.customerGroupID}`);
  // };

  const handleDelete = (row) => {
    setOpenDeleteDialog(true);
    setViewDetails(row)
  };

  const sendToDelete = (call) => {
    if (call.open === false) {
      setOpenDeleteDialog(false)
      setViewDetails({})
    }
    if (call.success === true) {
      httpclient
        .post(`deleteCustomerGroup?id=${viewDetails.id}&env=${env.value}`)
        .then(({ data }) => {
          if (data.status === 200) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenDeleteDialog(false);
            setViewDetails({});
            getAllCustomers();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  }



  const currentChange = (value, row) => {
   // console.log("value", value);
    if (value === "Update") {
      handleEdit(row);
    }
    // if (value === "Add Customer") {
    //   handleAdd(row);
    // }
    // if (value === "Reset Password") {
    //   handleResetPassword(row);
    // }
    if (value === "Delete") {
      handleDelete(row);
    }
  };


  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Header>
            <h1>List Customer Groups</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
        

          {/* <AddButton
            color="primary"
            variant="contained"
            onClick={handleAddNew}
          >
            <Add style={{ marginRight: "5px" }} fontSize="small" /> Add Customer Group
          </AddButton> */}
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            options={options}
            currentChange={currentChange}
            //handleView={handleView}
            handleSort={handleSort}
            currentColumn={currentColumn}
            loading={loading}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>
      {openDeleteDialog && <DeleteDialog sendToDelete={sendToDelete} />}

      {openViewDialog && (
        <ViewDetailsDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListCustomerGroups;

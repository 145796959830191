import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import  httpclient  from "../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
  },
}));

const RootCard = styled(Card)(({ theme }) => ({
  padding: "40px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

const PasswordBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "& svg": {
    cursor: "pointer",
    position: "absolute",
    right: "18px",
    top: "18px",
    color: "#999",
  },
}));

const SaveCustomerGroup = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    
    name: "",
   

  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [viewPassword_confirmation, setViewPassword_confirmation] = React.useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  

  //console.log("user details", userDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  


  const handleBack = () => {
    navigate(-1);
  };


  const handleSubmit = () => {


    
      httpclient
        .post(`saveCustomerGroup`, {
        
          name: userDetails.name,
         
         

        })
        .then(({ data }) => {
          if (data.status === 200) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setTimeout(() => {
              navigate(`/customers/list_customer_groups`);
            }, [1500]);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
    



  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Header>
        <h1>Save Customer Group</h1>
      </Header>
      <RootCard>
        <Grid container spacing={2}>


          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              name="name"
              type="text"
              label="Name"
              value={userDetails.name}
              onChange={(e) => handleChange(e)}
              sx={{ width: "100%" }}
            />
          </Grid>
         
          <Grid item xs={12}>
            <Box textAlign={"right"}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleBack}
                sx={{ marginRight: "10px" }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              //disabled={!passwordsMatch}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </RootCard>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SaveCustomerGroup;

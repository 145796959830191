import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, IconButton, styled, TextField } from "@mui/material";
import { Close, RemoveRedEye } from "@mui/icons-material";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const PasswordBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "& svg": {
    cursor: "pointer",
    position: "absolute",
    right: "18px",
    top: "18px",
    color: "#999",
  },
}));

export default function ResetDialog(props) {
  const [dialog, setDialog] = React.useState({
    open: true,
    success: false,
    email: (props.viewDetails && props.viewDetails.email) || "",
    type: (props.viewDetails && props.viewDetails.type) || "",
    id: (props.viewDetails && props.viewDetails.id) || "",
    oldPassword: "",
    password: "",
    password_confirmation: "",
  });
  const [viewOldPassword, setViewOldPassword] = React.useState(false);
  const [viewPassword, setViewPassword] = React.useState(false);
  const [viewPassword_confirmation, setViewPassword_confirmation] = React.useState(false);
  const [validate, setValidate] = React.useState(true);
  var loginData = localStorage.getItem("user-login");

  //var userValue = JSON.parse(userData);
  var loginValue = JSON.parse(loginData);


  React.useEffect(() => {
    props.sendReset(dialog);
    if (dialog.password && dialog.password_confirmation && (dialog.password === dialog.password_confirmation)) {
      setValidate(false)
    } else {
      setValidate(true);
    }
  }, [dialog]);


  const handleChange = e => {
    const { name, value } = e.target;
    setDialog({
      ...dialog,
      [name]: value
    })
  }


  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleSuccess = () => {
    setDialog({
      ...dialog,
      open: false,
      success: true,
    });
  };

  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          Reset Password
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        <DialogContent>
          <Box pt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  name="Email"
                  type="text"
                  label="Username"
                  value={dialog.email}
                  onChange={(e) => handleChange(e)}
                  sx={{ width: "100%" }}
                  disabled
                />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  name="ID"
                  type="text"
                  label="id"
                  value={dialog.id}
                  onChange={(e) => handleChange(e)}
                  sx={{ width: "100%" }}
                  disabled
                />
              </Grid> */}
              {loginValue.type === "admin" &&
                <Grid item xs={12} md={12}>

                  <PasswordBox>
                    <TextField
                      variant="outlined"
                      name="oldPassword"
                      type={viewOldPassword ? "text" : "password"}
                      label="Old Password"
                      value={dialog.oldPassword}
                      onChange={(e) => handleChange(e)}
                      sx={{ width: "100%" }}
                    />
                    <RemoveRedEye onClick={() => setViewOldPassword(!viewOldPassword)} />
                  </PasswordBox>
                </Grid>
              }
              <Grid item xs={12} md={12}>
                <PasswordBox>
                  <TextField
                    variant="outlined"
                    name="password"
                    type={viewPassword ? "text" : "password"}
                    label="Password"
                    value={dialog.password}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: "100%" }}
                  />
                  <RemoveRedEye onClick={() => setViewPassword(!viewPassword)} />
                </PasswordBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <PasswordBox>
                  <TextField
                    variant="outlined"
                    name="password_confirmation"
                    type={viewPassword_confirmation ? "text" : "password"}
                    label="Confirm Password"
                    value={dialog.password_confirmation}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: "100%" }}
                    helperText={dialog.password_confirmation.length > 0 && (dialog.password === dialog.password_confirmation ? "" : "Password does not match!")}
                  />
                  <RemoveRedEye onClick={() => setViewPassword_confirmation(!viewPassword_confirmation)} />
                </PasswordBox>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Close
          </Button>
          <Button
            onClick={handleSuccess}
            autoFocus
            style={{ marginLeft: "10px" }}
            variant="outlined"
            color="primary"
            disabled={validate}
          >
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Check, Clear, Close, KeyboardArrowLeft } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTable from "../../../Components/BasicTable";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}



const ViewProductDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [imageRows, setImageRows] = useState([]);
  const [warehouseRows, setWarehouseRows] = useState([]);
  const [variationRows, setVariationRows] = useState([]);
  const [productVariationRows, setProductVariationRows] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });

  const [inventoryRegistrationList, setInventoryRegistrationList] = useState([]);
  const [inventoryWriteOffList, setInventoryWriteOffList] = useState([]);
  const [productVariationList, setProductVariationList] = useState([]);
  const [inventoryDetails, setInventoryDetails] = useState("");
  const [variationDetails, setVariationDetails] = useState("");


  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  useEffect(() => {
    if (props.viewDetails && props.viewDetails.images) {
      const images = JSON.parse(props.viewDetails.images);
      const imageRows = images.map((image) => {
        return {
          //pictureID: image.pictureID,
          name: image.name,
          smallURL: image.smallURL,
          //external: image.external,
          //hostingProvider: image.hostingProvider,
          //hash: image.hash,
          //tenant: image.tenant,
        };
      });
      setImageRows(imageRows);
    }
  }, [props.viewDetails]);




  useEffect(() => {
    if (props.viewDetails && props.viewDetails.variationDescription) {
      const variation = JSON.parse(props.viewDetails.variationDescription);
      const variationRows = variation.map((variation) => {
        return {

          name: variation.name,
          value: variation.value,
          order: variation.order,
          dimensionID: variation.dimensionID,
          variationID: variation.variationID,

        };
      });
      setVariationRows(variationRows);
    }
  }, [props.viewDetails]);


  useEffect(() => {
    if (props.viewDetails.productID) {
      //when props details is loaded the get shippit detail api is called

      getInventoryRegistrations();

    }
  }, [props.viewDetails.productID]);





  const getInventoryRegistrations = () => {
    httpclient
      .get(`getInventoryRegistrations?env=${env.value}&productID=${props.viewDetails.productID}`)
      .then(({ data }) => {
        if (data.status === 200) {
          const inventoryRegistrationList = data.records.map(record => {
            if (record && record.rows) {
              const rowNew = JSON.parse(record.rows);
              const rowRows = rowNew.map(row => {
                return {
                  price: row.price,
                  amount: row.amount
                };
              });

              return { ...record, rows: rowRows };
            }

            if (record && record.warehouse) {
              const warehouseList = record.warehouse.map(warehouse => {
                return {
                  name: warehouse.name,
                  code: warehouse.code,
                  address: warehouse.address
                };
              });

              return { ...record, warehouse: warehouseList };
            }

            return record;
          });

          setInventoryRegistrationList(inventoryRegistrationList);
          setInventoryDetails("");
          //console.log("Result:", inventoryRegistrationList);
        } else {
          console.log("Error!");
        }
      });
  };




  useEffect(() => {
    if (props.viewDetails.productID) {


      getInventoryWriteOff();

    }
  }, [props.viewDetails.productID]);


  const getInventoryWriteOff = () => {
    httpclient.get(`getInventoryWriteOff?env=${env.value}&productID=${props.viewDetails.productID}`).then(({ data }) => {
      if (data.status === 200) {
        const inventoryWriteOffList = data.records;

        setInventoryWriteOffList(inventoryWriteOffList);
      } else {
        console.log("Error!");
      }
    });
  };



  useEffect(() => {
    if (props.viewDetails && props.viewDetails.productVariations) {
      const variations = JSON.parse(props.viewDetails.productVariations);
      const variationIds = variations.map(variation => variation.toString());
      const productVariationRows = variationIds.join(",");
      setProductVariationRows(productVariationRows);
    }
  }, [props.viewDetails]);



  // useEffect(() => {
  //   if (productVariationRows !== "") {
  //     setLoading(true);
  //     httpclient.get(`getProducts?productIDs=${productVariationRows}`)
  //       .then(({ data }) => {
  //         console.log(data); // log the response to the console
  //         if (data.status === 200) {
  //           //const productVariationList = data.records.data;
  //           const productVariationList = data.records.data.map(record => {
  //             if (record && record.variationDescription) {
  //               const rowNew = JSON.parse(record.variationDescription);
  //               const rowRows = rowNew.map(row => {
  //                 return {
  //                   name: row.name,
  //                   value: row.value,
  //                   order: row.order
  //                 };
  //               });
  //               console.log("Result1111111:", record);
  //               return { ...record, variationDescription: rowRows };
  //             } else {
  //               console.log("Result2221111:", record);
  //               return record;
  //             }
  //           });
  //           console.log(productVariationList); // log the product variation list to the console
  //           setProductVariationList(productVariationList);
  //           setVariationDetails("");
  //           setLoading(false); // set loading to false
  //         } else {
  //           console.log("Error!");
  //         }
  //       })
  //       .catch(error => {
  //         console.log(error); // log any errors to the console
  //         setLoading(false); // set loading to false
  //       });
  //   }
  // }, [productVariationRows !== ""]);

  useEffect(() => {
    if (productVariationRows !== "") {
      setLoading(true);
      httpclient.get(`getProducts?env=${env.value}&productIDs=${productVariationRows}`)
        .then(({ data }) => {
          //console.log(data); // log the response to the console
          if (data.status === 200) {
            const productVariationList = data.records.data.map(record => {
              if (record && record.variationDescription) {
                const rowNew = JSON.parse(record.variationDescription);
                const rowData = {};
                rowNew.forEach(row => {
                  rowData[row.name] = row.value;
                });
                //console.log("Result1111111:", record);
                return { ...record, ...rowData };
              } else {
               // console.log("Result2221111:", record);
                return record;
              }
            });
           // console.log("listed",productVariationList); // log the product variation list to the console
            setProductVariationList(productVariationList);
           // console.log("Resuz88888888:", variationDetails.Color);
            setVariationDetails("");
           
            setLoading(false); // set loading to false
          } else {
            console.log("Error!");
          }
        })
        .catch(error => {
          console.log(error); // log any errors to the console
          setLoading(false); // set loading to false
        });
    }
  }, [productVariationRows]);
  

  // console.log('productVariationRows', productVariationRows);
  // console.log('product variation', productVariationList);



  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };


  const handleView = (row) => {
    setInventoryDetails(row);
  };


  const handleBack = () => {
    setInventoryDetails("");
  };

  const handleView1 = (row) => {
    setVariationDetails(row);
  };


  const handleBack1 = () => {
    setVariationDetails("");
  };

  const imageColumns = [

    { id: "smallURL", name: "Landing Image" },


  ];
  const variationColumns = [
    { id: "name", name: "Variation Name" },
    { id: "value", name: "Value" },
    //{ id: "order", name: "Order" },
    { id: "dimensionID", name: "Erply Dimension ID" },
    { id: "variationID", name: "Erply Variation ID" },


  ];
  const variationColumns1 = [
    { id: "Color", name: "Color" },
    { id: "Code", name: "Code" },
    //{ id: "order", name: "Order" },



  ];
  const warehouseColumns = [
    { id: "name", name: "Warehouse Name" },
    { id: "code", name: "Code" },
    { id: "address", name: "Address" },



  ];
  const productColumns = [

    { id: "price", name: "Price($)" },
    { id: "amount", name: "Quantity" },



  ];
  // const productVariation = [


  //   { id: "productID", name: "Erply Product ID" },
  //   { id: "type", name: "Type" },
  //   { id: "name", name: "Name" },
  //   { id: "code", name: "Code" },
  //   { id: "groupName", name: "Group" },
  //   { id: "categoryName", name: "Category" },
  //   { id: "price", name: "Price($)" },
  //   { id: "status", name: "Status" },



  // ];
  const productVariation =[
    { id: "productID", name: "Erply Product ID" },
    { id: "type", name: "Type" },
    { id: "name", name: "Name" },
    { id: "Color", name: "Color" },
    { id: "Size", name: "Size" },
    { id: "code", name: "Code" },
    { id: "groupName", name: "Group" },
    { id: "categoryName", name: "Category" },
    { id: "price", name: "Price($)" },
    { id: "status", name: "Status" },
  ];
  
  
  const inventoryRegistration = [




    { id: "inventoryRegistrationID", name: "Erply Inventory Reg ID" },
    { id: "currencyCode", name: "Currency" },
    { id: "added", name: "Added" },
    { id: "created_at", name: "Created" },
    { id: "lastModified", name: "Last Modified" },
    { id: "confirmed", name: "Confirmation Status" },


  ];
  const inventoryWriteOff = [




    { id: "inventoryRegistrationID", name: "Erply Inventory Reg ID" },
    { id: "added", name: "Added" },
    { id: "created_at", name: "Created" },
    { id: "lastModified", name: "Last Modified" },
    { id: "confirmed", name: "Confirmation Status" },


  ];

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>{`View Product Details (${props.viewDetails.name || "_"
            }/${props.viewDetails.code || "_"
            })`}</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ margin: "0", padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Description" {...a11yProps(1)} />
                <Tab label="Images" {...a11yProps(2)} />
                {props.viewDetails.type === 'MATRIX' ? (
                  <Tab label="Product Variation" {...a11yProps(3)} />
                ) : (<Tab label="Variartion Description" {...a11yProps(3)} />)}

                {props.viewDetails.type === 'PRODUCT' && (
                  <Tab label="Inventory Reg" {...a11yProps(4)} />
                )}
                {props.viewDetails.type === 'PRODUCT' && (
                  <Tab label="Inventory Write Off" {...a11yProps(5)} />
                )}

              </Tabs>
            </AppBarTabs>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box pt={3}>
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Erply Product ID</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.productID || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Name</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.name || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Code</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.code || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Supplier Code</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.supplierCode || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Category</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.categoryName || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Brand</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.brand || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Type</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.type || "-"}</Values>
                    </FlexContent>



                  </Grid>
                  {/* Left Side */}

                  {/* Right Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Price</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>${props.viewDetails.price || null}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Price With VAT</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>${props.viewDetails.priceWithVat || null}</Values>
                    </FlexContent>


                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Display in Webshop</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.displayedInWebshop == "1" ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" />
                        )}{" "}
                      </Values>

                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Status</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.status == "ACTIVE" ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" />
                        )}{" "}
                      </Values>

                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Added Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.added).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Created Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.created_at).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Last Updated Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.updated_at).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>
                  </Grid>

                  {/* Right Side */}

                 
                </Grid>
              </Box>

            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box pt={3}>
                <Grid container spacing={2}>

                <Grid item xs={12}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Description</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.description}</Values>
                    </FlexContent>
                  </Grid>


                  <Grid item xs={12}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Long Description</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values dangerouslySetInnerHTML={{ __html: props.viewDetails.longdesc }} style={{ marginTop: '-58px' }}></Values>
                      
                    </FlexContent>
                  </Grid>
                </Grid>
              </Box>

            </TabPanel>



            {/* Product Details  props.viewDetails.variationDescription */}
            <TabPanel value={value} index={2} dir={theme.direction}>

              <BasicTable
                columns={imageColumns}
                rows={imageRows}

              />
            </TabPanel>

            {props.viewDetails.type === 'MATRIX' ? (
              <TabPanel value={value} index={3} dir={theme.direction}>
                {props.viewDetails.type === 'MATRIX' && variationDetails ? (
                  <>
                    <Box textAlign={"right"}>
                      <Button onClick={handleBack1}>
                        <KeyboardArrowLeft
                          fontSize="small"
                          sx={{ marginRight: "5px" }}
                        />{" "}
                        <span>Back</span>
                      </Button>
                    </Box>
                    <Grid container spacing={2}>
                      {/* Left Side */}
                      <Grid item xs={12} md={6}>
                        {/* <h3><i>Variation Details</i></h3> */}
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Name</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {variationDetails.name || "-"}
                          </Values>
                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Code</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>{variationDetails.code || "-"}</Values>
                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Supplier Code</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>{variationDetails.supplierCode || "-"}</Values>
                        </FlexContent>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Category</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>{variationDetails.categoryName || "-"}</Values>
                        </FlexContent>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Brand</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>{variationDetails.brand || "-"}</Values>
                        </FlexContent>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Type</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>{variationDetails.type || "-"}</Values>
                        </FlexContent>



                      </Grid>
                      {/* Left Side */}

                      {/* Right Side */}
                      <Grid item xs={12} md={6}>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Price</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>${variationDetails.price || null}</Values>
                        </FlexContent>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Price With VAT</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>${variationDetails.priceWithVat || null}</Values>
                        </FlexContent>


                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Display in Webshop</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {variationDetails.displayedInWebshop == "1" ? (
                              <Check color="primary" />
                            ) : (
                              <Clear color="primary" />
                            )}{" "}
                          </Values>

                        </FlexContent>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Status</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {variationDetails.status == "ACTIVE" ? (
                              <Check color="primary" />
                            ) : (
                              <Clear color="primary" />
                            )}{" "}
                          </Values>

                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Added Date</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {moment(variationDetails.added).format(
                              "ddd, MMM Do YYYY, h:mm a"
                            ) || "-"}
                          </Values>
                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Created Date</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {moment(variationDetails.created_at).format(
                              "ddd, MMM Do YYYY, h:mm a"
                            ) || "-"}
                          </Values>
                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Last Updated Date</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {moment(variationDetails.updated_at).format(
                              "ddd, MMM Do YYYY, h:mm a"
                            ) || "-"}
                          </Values>
                        </FlexContent>
                      </Grid>

                      {/* Right Side */}

                     


                     
                      <h3>Variation Description</h3>
                      <Grid item xs={12}>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Color</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {variationDetails.Color || "-"}
                          </Values>
                        </FlexContent>
                      </Grid>
                      <Grid item xs={12}>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Size</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {variationDetails.Size || "-"}
                          </Values>
                        </FlexContent>
                      </Grid>

                      {/* <BasicTable
                        columns={variationColumns1}
                        rows={variationDetails} /> */}
                    </Grid>

                  </>

                ) : (
                  <>

                    {productVariationList.length > 0 ? (
                      <BasicTable
                        columns={productVariation}
                        rows={productVariationList}
                        handleView={handleView1}
                        viewDetails={props.viewDetails} />
                    ) : (
                      <Box p={3}>
                        <span>No product variation record found.</span>
                      </Box>
                    )}
                  </>

                )}

              </TabPanel>
            ) : (
              <TabPanel value={value} index={3} dir={theme.direction}>
                {props.viewDetails.variationDescription.length > 0 &&
                  <BasicTable
                    columns={variationColumns}
                    rows={variationRows} />
                }
              </TabPanel>)
            }

            {/* Product Details */}

            <TabPanel value={value} index={4} dir={theme.direction}>
              {inventoryDetails ? (
                <>
                  <Box textAlign={"right"}>
                    <Button onClick={handleBack}>
                      <KeyboardArrowLeft
                        fontSize="small"
                        sx={{ marginRight: "5px" }}
                      />{" "}
                      <span>Back</span>
                    </Button>
                  </Box>
                  <Grid container spacing={2}>
                    {/* Left Side */}
                    <Grid item xs={12} md={6}>
                      <h3>Warehouse Details</h3>
                      {/* {inventoryDetails.warehouse.length > 0 && ( */}
                      {/* <BasicTable
                        columns={warehouseColumns}
                        rows={inventoryDetails.warehouse} /> */}
                      {/* )} */}
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Warehouse Name</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {inventoryDetails.warehouse.name || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Warehouse Location</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {inventoryDetails.warehouse.address || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Code</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {inventoryDetails.warehouse.code || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Email</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {inventoryDetails.warehouse.email || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Phone</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {inventoryDetails.warehouse.phone || "-"}
                        </Values>
                      </FlexContent>
                    </Grid>
                    {/* Left Side */}
                    {/* Right Side */}
                    <Grid item xs={12} md={6}>
                      <h3> Product Details</h3>

                      <BasicTable
                        columns={productColumns}
                        rows={inventoryDetails.rows} />

                    </Grid>
                    {/* Right Side */}
                  </Grid>
                </>
              ) : (
                <>
                  {
                    inventoryRegistrationList.length > 0 ? (
                      <BasicTable
                        columns={inventoryRegistration}
                        rows={inventoryRegistrationList}
                        handleView={handleView}
                        viewDetails={props.viewDetails}
                      />
                    )
                      : (

                        <Box p={3}>
                          <span>
                            No inventory registration record.
                          </span>
                        </Box>
                      )
                  }
                </>
              )}
            </TabPanel>

            <TabPanel value={value} index={5} dir={theme.direction}>
              {inventoryWriteOffList.length > 0 ? (
                <BasicTable
                  columns={inventoryWriteOff}
                  rows={inventoryWriteOffList} />
              ) : (
                <Box p={3}>
                  <span>
                    No inventory write off record.
                  </span>
                </Box>
              )}
            </TabPanel>
            {/* Product Details */}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewProductDialog;

import React, { useState } from 'react'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { AppBar, Box, Grid, styled, useTheme } from '@mui/material';
import ListProducts from '../ListProducts';
import ListProductVariations from '../ListProductVariations';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

const AppBarTabs = styled(AppBar)(({theme}) => ({
    background:"#fff",
    color:theme.palette.primary.dark,
    borderRadius:"4px",
    boxShadow:"none",
  }))

const MainProductPage = () => {
    const theme = useTheme();
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
    <div>

          <AppBarTabs position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Matrix Product" {...a11yProps(0)} />
            <Tab label="Products with include variations " {...a11yProps(1)} />
          </Tabs>
          </AppBarTabs>

          <TabPanel value={value} index={0} dir={theme.direction} style={{ margin:"0",padding:"0" }}>
            <ListProducts />    
            </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
            <ListProductVariations />    
        </TabPanel>
    </div>
  )
}

export default MainProductPage
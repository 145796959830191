import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

// const StyledActions = styled(DialogActions)(({theme}) => ({
//   margin:"5px 10px"
// }))

const DeleteDialog = (props) => {
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
  });

  useEffect(() => {
    props.sendToDelete(dialogDetails);
  }, [dialogDetails]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleYes = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
      success: true,
    });
  };

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          Delete user?

          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        <DialogContent>
          <Box pt={3} >Are you sure you want to delete this user?</Box>
        </DialogContent>
        <DialogActions styled={{ margin:"5px 10px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined" autoFocus>
            No
          </Button>
          <Button onClick={handleYes} color="primary" variant="outlined" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDialog;

const colors = {
    primary: {
        // main: "#2f243a",
        // light: "#bebbbb",
        // dark:"#444054",
        main: window.location.hostname === 'pswstaging.synccare.com.au' ? "#163617" : "#014868",
        light:"#f4f4f7",
        dark :"#252525",       
        shadow: "0px 5px 20px 0px rgba(0,0,0,0.07)",
        red: "#fb434f"
    },
    secondary: {
        light: "#E3F6FF",
        // main:"#d7d5d5",
        main: '#1d1d1d',
        dark: "#3f51b5"
    },
    success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: "#fff"
    },
    warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: "#fff"
    },
    textColor: "#f2f2f4"
}

export default colors;
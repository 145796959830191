import React, { useEffect, useState, useCallback } from "react";
import TableComponent from "../TableComponent";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import { Add, ArrowForward, Close, FilterList } from "@mui/icons-material";
import httpclient from "../../../utils/httpClient";

import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import DeactivateDialog from "../DeactivateDialog";
import ResetDialog from "../ResetDialog";
import DeleteDialog from "../DeleteDialog";
//import { useHistory } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
  { id: "sn", name: "SN" },
  { id: "id", name: "" },
  { id: "first_name", name: "First Name" },
  { id: "last_name", name: "Last Name" },
  { id: "email", name: "Email" },
  { id: "type", name: "User Type" },
  { id: "status", name: "User Active" },
  { id: "actions", name: "Actions" },
];

const options = [
  { id: "edit", name: "Edit", action: "handleEdit" },
  { id: "deactivate", name: "Deactivate", action: "handleDeactivate" },
  { id: "reset", name: "Reset Password", action: "handleResetPassword" },
  { id: "delete", name: "Delete", action: "handleDelete" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginLeft: "10px",
  "& svg": {
    fontSize: "15px",
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ListUsers = () => {
  const navigate = useNavigate();
  //const history = useHistory();
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});

  const [openActiveDialog, setOpenActiveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [loading, setLoading] = useState(false);
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  var loginData = localStorage.getItem("user-login");

  //var userValue = JSON.parse(userData);
  var loginValue = JSON.parse(loginData);
  

  const [filterData, setFilterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    type: "",
    status: "",

    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    type: "",
    status: "",

    submit: false,
  });

  // useEffect(() => {
  //   getAllUsers();
  // }, []);

  useEffect(() => {
    if (
      filterData.first_name === "" &&
      filterData.last_name === "" &&
      filterData.email === "" &&
      filterData.type === "" &&
      filterData.status === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.first_name === " ") filterData.first_name = "";
    if (filterData.last_name === " ") filterData.last_name = "";
    if (filterData.email === " ") filterData.email = "";
    if (filterData.type === " ") filterData.type = "";
    if (filterData.status === " ") filterData.status = "";


    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let userStorage = JSON.parse(localStorage.getItem("user_filter"));
    userStorage !== null && setFilterData(userStorage);

    userStorage == null
      ? getAllUsers()
      : userStorage.first_name == "" &&
        userStorage.last_name == "" &&
        userStorage.email == "" &&
        userStorage.type == "" &&
        userStorage.status == "" &&

        userStorage.removed == false
        ? getAllUsers()
        : console.log("users!");
  }, []);


  const handleLogout = () => {
    localStorage.clear();
    //setOpenDeleteDialog(false)
    setTimeout(() => {
      navigate("/user-login");
    }, 2000);
    
  };

  // const logoutHandler = useCallback(() => {
  //   handleLogout();
  // }, [handleLogout]);

  const getAllUsers = () => {
    setLoading(true);
    httpclient.get(`getUsers?page=${page}&recordsOnPage=${recordsOnPage}`).then(({ data }) => {
      if (data.records) {
        setRows(data.records.data);
        setTotal(data.records.total);
        setRecordsOnPage(data.records.per_page);
        setPage(data.records.current_page);
        setFrom(data.records.from);
        setTo(data.records.to);
        setLoading(false);

      }
    });
  }; 



  const handleSort = (column) => {
    //setDirection((prevDirection) => !prevDirection);
    setDirection(!direction);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getUsers?first_name=${filterData.first_name
          }&last_name=${filterData.last_name
          }&email=${filterData.email
          }&type=${filterData.type
          }&status=${filterData.status
          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          }
        })
      : httpclient
        .get(
          `getUsers?sort_by=${column}&page=${page}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}`
        )
        .then(({ data }) => {
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          } else {
            console.log("Error!");
        }

      });
  };



  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient.get(`getUsers?first_name=${filterData.first_name
        }&last_name=${filterData.last_name
        }&email=${filterData.email
        }&type=${filterData.type
        }&status=${filterData.status
        }&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${recordsOnPage}&page=${page}`).then(({ data }) => {
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          }
        }) : httpclient
          .get(
            `getUsers?sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
            }&recordsOnPage=${recordsOnPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.records) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRecordsOnPage(data.records.per_page);
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);

            }
          });
  };

  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);
    submittedData.submit
      ?
      httpclient.get(`getUsers?first_name=${filterData.first_name
        }&last_name=${filterData.last_name
        }&email=${filterData.email
        }&type=${filterData.type
        }&status=${filterData.status
        }&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${+event.target.value}&page=${page}`)
        .then(({ data }) => {
          setLoading(true);
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          }
        }) : httpclient
          .get(
            `getUsers?sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
            }&recordsOnPage=${+event.target.value}&page=${page}`
          )
          .then(({ data }) => {
            if (data.records) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRecordsOnPage(data.records.per_page);
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);

            }
          });
  };


  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      first_name: filterData.first_name,
      last_name: filterData.last_name,
      email: filterData.email,
      type: filterData.type,
      status: filterData.status,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("user_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.first_name ||
      filterData.last_name ||
      filterData.email ||
      filterData.type ||
      filterData.status

    ) {
      httpclient.get(`getUsers?first_name=${filterData.first_name
        }&last_name=${filterData.last_name
        }&email=${filterData.email
        }&type=${filterData.type
        }&status=${filterData.status
        }&recordsOnPage=${recordsOnPage}&page=${1}&strictFilter=true`)
        .then(({ data }) => {
          setLoading(true);
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          }
        });
    } else {
      getAllUsers();
    }
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };



  const hadleAddNew = () => {
    navigate(`/add_user`);
  };

  const handleEdit = (row) => {
    navigate(`/get_user/${row.id}`);
  };

  const handleDeactivate = (row) => {
    setOpenActiveDialog(true);
    setViewDetails(row);
  };

  const sendDeactivate = (call) => {
    if (call.open === false) {
      setOpenActiveDialog(false);
      setViewDetails({});
    }
    if (call.success === true) {
      httpclient
        .post(`updateUserStatus/${viewDetails.id}`)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenActiveDialog(false);
            setViewDetails({});
            getAllUsers();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };

  const handleResetPassword = (row) => {
    setOpenResetDialog(true);
    setViewDetails(row);
  };

  const sendReset = (call) => {
    if (call.open === false) {
      setOpenResetDialog(false);
      setViewDetails({});
    }
    if (call.success === true) {
      httpclient
        .post(`updatePassword/${loginValue.id}`, {
          id: call.id,
          oldPassword: call.oldPassword,
          password: call.password,
          password_confirmation: call.password_confirmation,
        })
        .then(({ data }) => {
          if (data.status === 200) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenActiveDialog(false);
            setViewDetails({});
            getAllUsers();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };

  const handleDelete = (row) => {
    setOpenDeleteDialog(true);
    setViewDetails(row)
  };

  const sendToDelete = (call) => {
    if (call.open === false) {
      setOpenDeleteDialog(false)
      setViewDetails({})
    }
    if (call.success === true) {
      httpclient
        .post(`deleteUser?id=${viewDetails.id}`)
        .then(({ data }) => {
          if (data.status === 200) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            if (data.logout === true) {
              setOpen(true);
              setMessageState("warning");
              //message="Your account has been deleted"
              setMessage(data.message);
              handleLogout();                          
            } else {
            setOpenDeleteDialog(false);
            setViewDetails({});
            getAllUsers();
            }
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  }

  const currentChange = (value, row) => {
    console.log("value", value);
    if (value === "Edit") {
      handleEdit(row);
    }
    if (value === "Deactivate") {
      handleDeactivate(row);
    }
    if (value === "Reset Password") {
      handleResetPassword(row);
    }
    if (value === "Delete") {
      handleDelete(row);
    }
  };



  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Users</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button> */}
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
          <AddButton
            color="primary"
            variant="contained"
            onClick={hadleAddNew}
          >
            <Add style={{ marginRight: "5px" }} fontSize="small" /> Add User
          </AddButton>
        </Grid>
        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>First Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="first_name"
                      value={filterData.first_name}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Last Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="last_name"
                      value={filterData.last_name}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Email</InputLabel>
                    <TextField
                      variant="outlined"
                      name="email"
                      value={filterData.email}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>User Type</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.type}
                        // label="Exclusive Pending"
                        name="type"
                       
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value={"superadmin"}>Super Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                 
                  <Grid item xs={12} md={4}>
                    <InputLabel>Status</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.status}
                        // label="Exclusive Pending"
                        name="status"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Active</MenuItem>
                        <MenuItem value={"0"}>Not Active</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>



                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.first_name ||
          submittedData.last_name ||
          submittedData.email ||
          submittedData.type ||
          //submittedData.locationInWarehouseName ||
          submittedData.status ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.first_name && (
                <p>
                  <span>First Name: {submittedData.first_name}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("first_name")}
                  />
                </p>
              )}
              {submittedData.last_name && (
                <p>
                  <span>Last Name: {submittedData.last_name}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("last_name")}
                  />
                </p>
              )}
              {submittedData.email && (
                <p>
                  <span>Email: {submittedData.email}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("email")}
                  />
                </p>
              )}
              {submittedData.type && (
                  <p><span>
                  User Type: {" "}
                  {submittedData.type === "admin"
                    ? "Admin"
                    : "Super Admin"}
                </span>
                    
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("type")}
                    />
                  </p>
                )}
              {submittedData.status && (
                  <p><span>
                  Status: {" "}
                  {submittedData.status === "0"
                    ? "Not Active"
                    : "Active"}
                </span>
                    
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("status")}
                    />
                  </p>
                )}
             

              
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}


        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            options={options}
            sort={true}
            currentChange={currentChange}
            handleSort={handleSort}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            filterData={filterData}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>

      {openDeleteDialog && <DeleteDialog sendToDelete={sendToDelete} />}

      {openActiveDialog && (
        <DeactivateDialog
          viewDetails={viewDetails}
         sendDeactivate={sendDeactivate}
        />
      )}
      {openResetDialog && (
        <ResetDialog viewDetails={viewDetails} sendReset={sendReset} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListUsers;






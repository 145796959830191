import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import {
    Box,
    Button,
    Card,
    Collapse,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    Snackbar,
} from "@mui/material";
//import ViewInventoryDialog from "../ViewInventoryDialog";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
    { id: "sn", name: "SN" },
    { id: "WEBSKU", name: "Web SKU" },
    { id: "ItemName", name: "Item Name" },
    { id: "variations_count", name: "# of Items" },
    // { id: "ColourName", name: "Colour" },
    // { id: "SizeID", name: "Size" },
    { id: "DefaultStore", name: "Default Store" },
    { id: "CustomerGroup", name: "Customer Group" },
    { id: "ERPLYFLAG", name: "Entity" },
    { id: "updated_at", name: "Last Synced On" },
    { id: "url", name: "Resync" },

];


const FilteredBox = styled(Box)(({ theme }) => ({
    background: "#f9f9f9",
    padding: "5px 10px",
    borderRadius: "5px",
    "& p": {
        margin: "0",
        marginRight: "10px",
        display: "inline-block",
        background: "#dedede",
        borderRadius: "10px",
        padding: "2px 5px",
    },
    "& svg": {
        fontSize: "15px",
        cursor: "pointer",
        position: "relative",
        top: "3px",
        background: theme.palette.primary.dark,
        color: "#fff",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "2px",
    },
}));

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ResyncProduct = () => {
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewDetails, setViewDetails] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [singleLoading, setSingleLoading] = useState(false);
    //const [direction, setDirection] = useState(false);
    var [direction, setDirection] = useState("");
    const [currentColumn, setCurrentColumn] = useState("");
    const [rows, setRows] = useState([]);
    const [entity, setEntity] = useState([]);
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(
        configRecordsOnPage && configRecordsOnPage
            ? configRecordsOnPage && configRecordsOnPage
            : 20
    );


    const [recordsOnPage, setRecordsOnPage] = useState(
        configRecordsOnPage && configRecordsOnPage
            ? configRecordsOnPage && configRecordsOnPage
            : 20
    );
    const [total, setTotal] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");


    const [filterData, setFilterData] = useState({
        ItemName: "",
        WEBSKU: "",
        DefaultStore: "",
        CustomerGroup: "",
        ERPLYFLAG: "",
        remove: false,
    });

    const [submittedData, setSubmittedData] = useState({
        ItemName: "",
        WEBSKU: "",
        DefaultStore: "",
        CustomerGroup: "",
        ERPLYFLAG: "",
        submit: false,
    });



    useEffect(() => {
        if (
            filterData.ItemName === "" &&
            filterData.WEBSKU === "" &&
            filterData.DefaultStore === "" &&
            filterData.CustomerGroup === "" &&
            filterData.ERPLYFLAG

        ) {
            setSubmittedData({
                ...submittedData,
                submit: false,
            });
        }
        if (filterData.ItemName === " ") filterData.ItemName = "";
        if (filterData.WEBSKU === " ") filterData.WEBSKU = "";
        if (filterData.DefaultStore === " ") filterData.DefaultStore = "";
        if (filterData.CustomerGroup === " ") filterData.CustomerGroup = "";
        if (filterData.ERPLYFLAG === " ") filterData.ERPLYFLAG = "";


        filterData.remove === true && handleFilter();
    }, [filterData]);

    useEffect(() => {
        let currentpolicy = JSON.parse(
            localStorage.getItem("resyncproductlist_filter")
        );
        currentpolicy !== null && setFilterData(currentpolicy);

        currentpolicy == null
            ? getResyncProduct()
            : currentpolicy.ItemName == "" &&
                currentpolicy.WEBSKU == "" &&
                currentpolicy.DefaultStore == "" &&
                currentpolicy.CustomerGroup == "" &&
                currentpolicy.ERPLYFLAG == "" &&

                currentpolicy.removed == false
                ? getResyncProduct()
                : console.log("current price policy!");
    }, []);

    useEffect(() => {
        getEntity();
        //getAllProductGroups();
    }, [])


    const getEntity = () => {

        httpclient
            .get(`getEntity?env=${env.value}`)
            .then(({ data }) => {
                if (data.status === 200) {
                    const records = data.records; // Extract the array of records from the response
                    setEntity(records);

                } else {
                    console.log("Error!");
                }
            });
    };


    const getResyncProduct = () => {
        setLoading(true);
        httpclient.get(`getProductMagic?env=${env.value}&recordsOnPage=${recordsOnPage}&page=${page}`)
            // &includeMatrixVariations=${0}
            .then(({ data }) => {
                if (data.status === 200) {
                    const records = data.records;
                    setRows(
                        records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        })
                    );

                    setTotal(records.total);
                    setRecordsOnPage(records.per_page);
                    setPage(records.current_page);
                    setFrom(records.from);
                    setTo(records.to);
                    setLoading(false);
                } else {
                    console.log("Error!");
                }
            });
    };




    const handleSort = (column) => {
        //setDirection((prevDirection) => !prevDirection);
        setDirection(!direction);
        setCurrentColumn(column);
        submittedData.submit
            ?
            httpclient
                .get(
                    `getProductMagic?env=${env.value}&ItemName=${filterData.ItemName
                    }&WEBSKU=${filterData.WEBSKU
                    }&DefaultStore=${filterData.DefaultStore
                    }&CustomerGroup=${filterData.CustomerGroup
                    }&ERPLYFLAG=${filterData.ERPLYFLAG
                    }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&recordsOnPage=${recordsOnPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];

                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }
                })
            : httpclient
                .get(
                    `getProductMagic?env=${env.value}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&recordsOnPage=${recordsOnPage}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }

                });
    };


    const handleChangePage = (event, page) => {

        //setPage(page);
        setLoading(true);
        submittedData.submit
            ?
            httpclient.get(`getProductMagic?env=${env.value}&ItemName=${filterData.ItemName
                }&WEBSKU=${filterData.WEBSKU
                }&DefaultStore=${filterData.DefaultStore
                }&CustomerGroup=${filterData.CustomerGroup
                }&ERPLYFLAG=${filterData.ERPLYFLAG
                }&sort_by=${currentColumn
                }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
                }&recordsOnPage=${recordsOnPage}&page=${page}`
            )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }
                })
            :
            httpclient
                .get(
                    `getProductMagic?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
                    }&recordsOnPage=${recordsOnPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }

                });
    };



    const handleChangeRecordsOnPage = (event) => {
        //const recordsOnPage = +event.target.value;
        setRecordsOnPage(+event.target.value);
        localStorage.setItem("configRecordsOnPage", event.target.value);

        submittedData.submit
            ?
            httpclient
                .get(`getProductMagic?env=${env.value}&ItemName=${filterData.ItemName
                    }&WEBSKU=${filterData.WEBSKU
                    }&DefaultStore=${filterData.DefaultStore
                    }&CustomerGroup=${filterData.CustomerGroup
                    }&ERPLYFLAG=${filterData.ERPLYFLAG
                    }&sort_by=${currentColumn
                    }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
                    }&page=${page}&recordsOnPage=${+event.target.value}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }
                })
            :
            httpclient
                .get(
                    `getProductMagic?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
                    }&recordsOnPage=${+event.target.value}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }

                });
    };


    // const handleView = (row) => {
    //   setSingleLoading(true);
    //   setOpenViewDialog(true);
    //   httpclient
    //     .get(`getInventoryTransfer?inventoryRegistrationID=${row.inventoryRegistrationID || row}`)
    //     .then(({ data }) => {
    //       if (data.records)
    //         setViewDetails(data.records.data[0]);

    //         setSingleLoading(false);


    //     });
    // };

    // const sendDetails = (callback) => {
    //   if (callback.open === false) {
    //     setOpenViewDialog(false);
    //     setViewDetails({});
    //   }
    //   if (callback.refetch === true) {
    //     handleView(callback.productID);
    //     setTimeout(() => {
    //       getAllInventoryTransfer();
    //     }, 1000)
    //   }
    // };





    const handleFilter = () => {
        setSubmittedData({
            ...submittedData,
            ItemName: filterData.ItemName,
            WEBSKU: filterData.WEBSKU,
            DefaultStore: filterData.DefaultStore,
            CustomerGroup: filterData.CustomerGroup,
            ERPLYFLAG: filterData.ERPLYFLAG,

            submit: true,
        });
        filterData.remove = true;
        localStorage.setItem("resyncproductlist_filter", JSON.stringify(filterData));
        setLoading(true);
        if (
            filterData.ItemName ||
            filterData.WEBSKU ||
            filterData.DefaultStore ||
            filterData.CustomerGroup ||
            filterData.ERPLYFLAG

        ) {
            httpclient
                .get(
                    `getProductMagic?env=${env.value}&ItemName=${filterData.ItemName
                    }&WEBSKU=${filterData.WEBSKU
                    }&DefaultStore=${filterData.DefaultStore
                    }&CustomerGroup=${filterData.CustomerGroup
                    }&ERPLYFLAG=${filterData.ERPLYFLAG
                    }&recordsOnPage=${recordsOnPage}&page=${1}`
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        const records = data.records;
                        setRows(records.data.map((record) => {
                            const product = {};
                            columns.forEach((column) => {
                                product[column.id] = record[column.id];
                            });
                            return product;
                        }));
                        setTotal(records.total);
                        setRecordsOnPage(records.per_page);
                        setPage(records.current_page);
                        setFrom(records.from);
                        setTo(records.to);
                        setLoading(false);
                    } else {
                        console.log("Error!");
                    }
                });
        } else {
            getResyncProduct();
        }
    };

    const hadleFilterOpen = () => {
        setFilterOpen((prev) => !prev);
    };

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilterData({
            ...filterData,
            [name]: value,
            remove: false,
        });
    };

    const handleRemove = (data) => {
        setFilterData({
            ...filterData,
            [data]: "",
            remove: true,
        });

        setSubmittedData({
            ...submittedData,
            [data]: "",
        });
    };




    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Header>
                        <h1>List Resync Product</h1>
                    </Header>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
                        Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button>
                </Grid>

                {/* Filter */}
                <Grid item xs={12}>
                    <Collapse in={filterOpen}>
                        <Card>
                            <Box p={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Web SKU</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="WEBSKU"
                                            value={filterData.WEBSKU}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Item Name</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="ItemName"
                                            value={filterData.ItemName}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Default Store</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="DefaultStore"
                                            value={filterData.DefaultStore}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            fullWidth
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} md={6}>
                                        <InputLabel>Customer Group</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="CustomerGroup"
                                            value={filterData.CustomerGroup}
                                            onChange={handleChangeFilter}
                                            fullWidth
                                        />
                                    </Grid> */}

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Customer Group</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={filterData.CustomerGroup}
                                                // label="Exclusive Pending"
                                                name="CustomerGroup"
                                                onChange={handleChangeFilter}
                                            >
                                                <MenuItem value={""}>Select</MenuItem>
                                                <MenuItem value={"Wholesale"}>Wholesale</MenuItem>
                                                <MenuItem value={"Retail"}>Retail</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Entity</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={filterData.ERPLYFLAG}
                                                // label="Exclusive Pending"
                                                name="ERPLYFLAG"
                                                onChange={handleChangeFilter}
                                            >

                                                <MenuItem value={""}>Select</MenuItem>
                                                {entity.map((ent, index) => (
                                                    <MenuItem value={ent.ENTITY}>{ent.ENTITY}</MenuItem>

                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>



                                    <Grid item xs={12}>
                                        <Box textAlign={"right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleFilter}
                                            >
                                                Filter{" "}
                                                <ArrowForward
                                                    fontSize="small"
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Collapse>
                </Grid>

                {submittedData.ItemName ||
                    submittedData.WEBSKU ||
                    submittedData.DefaultStore ||
                    submittedData.CustomerGroup ||
                    submittedData.ERPLYFLAG
                    ? (
                        <Grid item xs={12}>
                            <FilteredBox>
                                <span>Filtered: </span>
                                {submittedData.ItemName && (
                                    <p>
                                        <span>Item Name: {submittedData.ItemName}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("ItemName")}
                                        />
                                    </p>
                                )}
                                {submittedData.WEBSKU && (
                                    <p>
                                        <span>Web SKU: {submittedData.WEBSKU}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("WEBSKU")}
                                        />
                                    </p>
                                )}
                                {submittedData.DefaultStore && (
                                    <p>
                                        <span>Default Store: {submittedData.DefaultStore}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("DefaultStore")}
                                        />
                                    </p>
                                )}
                                {submittedData.CustomerGroup && (
                                    <p>
                                        <span>Customer Group: {submittedData.CustomerGroup}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("CustomerGroup")}
                                        />
                                    </p>
                                )}
                                {submittedData.ERPLYFLAG && (
                                    <p>
                                        <span>Entity: {submittedData.ERPLYFLAG}</span>
                                        <Close
                                            fontSize="small"
                                            onClick={() => handleRemove("ERPLYFLAG")}
                                        />
                                    </p>
                                )}



                            </FilteredBox>
                        </Grid>
                    ) : (
                        <Box></Box>
                    )}
                {/* Filter */}

                <Grid item xs={12}>
                    <TableComponent
                        columns={columns}
                        rows={rows}
                        sort={true}
                        handleSort={handleSort}
                        // handleView={handleView}
                        loading={loading}
                        direction={direction}
                        currentColumn={currentColumn}
                        page={page}
                        total={total && total}
                        fromTable={from}
                        toTable={to}
                        recordsOnPage={recordsOnPage}
                        filterData={filterData}
                        handleChangePage={handleChangePage}
                        handleChangeRecordsOnPage={handleChangeRecordsOnPage}
                    />
                </Grid>
            </Grid>

            {/* {openViewDialog && (
        <ViewInventoryDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )} */}

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ResyncProduct;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, styled } from '@mui/material';
import { Check, Clear } from "@mui/icons-material";
import moment from 'moment/moment';

import FsLightbox from "fslightbox-react";

const ImageCell = styled("div")(({ theme }) => ({
  margin: "10px",
  width: "190px",
  borderRadius: "5px",
  overflow: "hidden",
  "& img": {
    width: "190px",
    height: "210px",
    objectFit: "cover",
    transition: "0.5s",
    boxShadow: theme.palette.primary.shadow,
    marginBottom: "10px",
    overflow: "hidden",
  },
  "& img:hover": {
    transform: "scale(1.1)",
  },
}));

const ImageDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  flexWrap: "wrap",
  marginBottom: "10px",
}));





export default function BasicTable(props) {
  const [imgIndex1, setImgIndex1] = React.useState(0);
  const [togglerLanding, setTogglerLanding] = React.useState(false);

  const [showLightbox, setShowLightbox] = React.useState(false);

  const handleToggleLightbox = () => {
    setShowLightbox(!showLightbox);
  };

  return (

    //500px
    <TableContainer component={Paper} style={{ overflowY: "auto", height: "auto" }}>
      <Table sx={{ minWidth: 550 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.columns.map(col => (
              <TableCell style={{ fontWeight: "700" }}>{col.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {props.rows.length > 0 ?
          <TableBody>
            {props.rows.map((row) => (
              <TableRow
                key={row.name}

                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {props.columns.map((col, index) => (
                  col.id === "reason" ?
                    <TableCell component="th" scope="row" style={{ width: "200px" }}>
                      {row[col.id]}
                    </TableCell>
                    :
                    <TableCell component="th" scope="row" style={{ cursor: "pointer" }} onClick={() => props.handleView(row)} >
                      {
                        col.id === "smallURL" ?
                          <ImageDiv>
                            <ImageCell
                              onClick={() => handleToggleLightbox()}
                            >
                              <img src={row[col.id]} alt="thumbnail" />
                              <Box textAlign="center">
                                {row[col.id].split("/").pop().replaceAll(".jpg", "").replaceAll(".png", "")}
                              </Box>
                            </ImageCell>

                            <FsLightbox
                              toggler={showLightbox}
                              sources={[row[col.id]]}
                              //sourceIndex={imgIndex1}
                              type="image"
                            // types={[
                            //   ...new Array(row[col.id]).fill('image')
                            // ]}
                            />

                          </ImageDiv>
                          //<img width="100px" height="100px" src={row[col.id]} alt="thumbnail" /> 
                          :
                          col.id === "confirmed" ? (
                            row[col.id] === 1 ? (
                              <Check color="primary" />
                            ) : (
                              <Clear color="primary" />
                            )
                          ) :
                            col.id === "showInWebshop" ? (
                              row[col.id] === "1" ? (
                                <Check color="primary" />
                              ) : (
                                <Clear color="primary" />
                              )
                            ) : col.id === "active" ? (
                              row[col.id] === 1 ? (
                                <Check color="primary" />
                              ) : (
                                <Clear color="primary" />
                              )
                            )
                              : col.id === "added" ?
                                (row[col.id] !== "0000-00-00 00:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : "-"

                                : col.id === "lastModified" ?
                                  (row[col.id] !== "0000-00-00 00:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : "-"
                                  : col.id === "created_at" ?
                                    (row[col.id] !== "1970-01-01 00:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : "-"
                                    : col.id === "updated_at" ?
                                      (row[col.id] !== "1970-01-01 00:00:00") ? row[col.id] !== null ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" : "-"
                                      : col.id === "price" ?

                                        (parseFloat(row[col.id]).toFixed(2))
                                        :

                                        row[col.id]}
                    </TableCell>

                ))}
              </TableRow>
            ))}
          </TableBody>
          :
          <TableBody>
            <TableRow style={{ position: "relative", width: "100%" }}>
              <Box textAlign="center" style={{ position: "absolute", right: "50%" }} mt={3}>
                No Data Found
              </Box>
            </TableRow>
          </TableBody>
        }
      </Table>
    </TableContainer>
  );
}

import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import { Add, ArrowForward, Close, FilterList } from "@mui/icons-material";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import ViewDetailsDialog from "../ViewDetailsDialog";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
  { id: "sn", name: "SN" },
  { id: "employeeID", name: "" },
  { id: "employeeName", name: "Name" },
  { id: "username", name: "User Name" },
  { id: "email", name: "Email" },
  { id: "pointsOfSale", name: "POS" },
  //{ id: "actions", name: "Actions" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginLeft: "10px",
  "& svg": {
    fontSize: "15px",
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ListEmployees = () => {
  const navigate = useNavigate();
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});

  const [openActiveDialog, setOpenActiveDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [loading, setLoading] = useState(false);
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [filterData, setFilterData] = useState({
    employeeName: "",
    pointsOfSale: "",

    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    employeeName: "",
    pointsOfSale: "",


    submit: false,
  });

  

  useEffect(() => {
    if (
      filterData.employeeName === "" &&
      filterData.pointsOfSale === ""

    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.employeeName === " ") filterData.employeeName = "";
    if (filterData.pointsOfSale === " ") filterData.pointsOfSale = "";



    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let userStorage = JSON.parse(localStorage.getItem("employees_filter"));
    userStorage !== null && setFilterData(userStorage);

    userStorage == null
      ? getAllEmployees()
      : userStorage.employeeName == "" &&
        userStorage.pointsOfSale == "" &&


        userStorage.removed == false
        ? getAllEmployees()
        : console.log("users!");
  }, []);

  const getAllEmployees = () => {
    setLoading(true);
    httpclient.get(`getEmployees?env=${env.value}&page=${page}&recordsOnPage=${recordsOnPage}`).then(({ data }) => {
      if (data.records) {
        setRows(data.records.data);
        setTotal(data.records.total);
        setRecordsOnPage(data.records.per_page);
        setPage(data.records.current_page);
        setFrom(data.records.from);
        setTo(data.records.to);
        setLoading(false);

      }
    });
  };


  const handleSort = (column) => {
    //setDirection((prevDirection) => !prevDirection);
    setDirection(!direction);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getEmployees?env=${env.value}&employeeName=${filterData.employeeName
          }&pointsOfSale=${filterData.pointsOfSale
          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          }
        })
      : httpclient
        .get(
          `getEmployees?env=${env.value}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);
          } else {
            console.log("Error!");
        }

      });
  };



  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient.get(`getEmployees?env=${env.value}&employeeName=${filterData.employeeName
        }&pointsOfSale=${filterData.pointsOfSale
        }&sort_by=${currentColumn
        }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${recordsOnPage}&page=${page}`).then(({ data }) => {
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          }
        }) : httpclient
          .get(
            `getEmployees?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
            }&recordsOnPage=${recordsOnPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.records) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRecordsOnPage(data.records.per_page);
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);

            }
          });
  };

  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);
    submittedData.submit
      ?
      httpclient.get(`getEmployees?env=${env.value}&employeeName=${filterData.employeeName
        }&pointsOfSale=${filterData.pointsOfSale
        }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
        }&recordsOnPage=${+event.target.value}&page=${page}`)
        .then(({ data }) => {
          setLoading(true);
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          }
        }) : httpclient
          .get(
            `getEmployees?env=${env.value}&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
            }&recordsOnPage=${+event.target.value}&page=${page}`
          )
          .then(({ data }) => {
            if (data.records) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRecordsOnPage(data.records.per_page);
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);

            }
          });
  };


  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      employeeName: filterData.employeeName,
      pointsOfSale: filterData.pointsOfSale,

      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("employees_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.employeeName ||
      filterData.pointsOfSale


    ) {
      httpclient.get(`getEmployees?env=${env.value}&employeeName=${filterData.employeeName
        }&pointsOfSale=${filterData.pointsOfSale
        }&recordsOnPage=${recordsOnPage}&page=${1}`)
        .then(({ data }) => {
          setLoading(true);
          if (data.records) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);

          }
        });
    } else {
      getAllEmployees();
    }
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getEmployees?env=${env.value}&employeeID=${row.employeeID || row}`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records.data[0]);
        setSingleLoading(false);

      });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.employeeID);
      setTimeout(() => {
        getAllEmployees();
      }, 1000)
    }
  };





  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Employees</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >

          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
          
        </Grid>
        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Employee Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="employeeName"
                      value={filterData.employeeName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>POS</InputLabel>
                    <TextField
                      variant="outlined"
                      name="pointsOfSale"
                      value={filterData.pointsOfSale}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>


                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.employeeName ||
          submittedData.pointsOfSale
          ? (
            <Grid item xs={12}>
              <FilteredBox>
                <span>Filtered: </span>
                {submittedData.employeeName && (
                  <p>
                    <span>Employee Name: {submittedData.employeeName}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("employeeName")}
                    />
                  </p>
                )}
                {submittedData.pointsOfSale && (
                  <p>
                    <span>Points of Sale: {submittedData.pointsOfSale}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("pointsOfSale")}
                    />
                  </p>
                )}

              </FilteredBox>
            </Grid>
          ) : (
            <Box></Box>
          )}
        {/* Filter */}


        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            //options={options}
            sort={true}
            //currentChange={currentChange}
            handleSort={handleSort}
            handleView={handleView}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            filterData={filterData}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>


      {openViewDialog && (
        <ViewDetailsDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListEmployees;






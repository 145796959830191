
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import { Add, ArrowForward, Close, FilterList } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import ViewDetailsDialog from "../ViewDetailsDialog.js/index.js";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import DeleteDialog from "../DeleteDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
 

  { id: "sn", name: "SN" },
  { id: "id", name: "" },
  { id: "customerID", name: "" },
  { id: "fullName", name: "Name" },
  { id: "mobile", name: "Mobile No." },
  { id: "email", name: "Email" },
  { id: "created_at", name: "Created Date" },
  


  // { id: "actions", name: "Actions" },
];

const options = [
  { id: "edit", name: "Update", action: "handleEdit" },
  { id: "delete", name: "Delete", action: "handleDelete" },
];


const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));


const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginLeft: "10px",
  "& svg": {
    fontSize: "15px",
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));


const ListCustomers = () => {
  const navigate = useNavigate();

  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [showCustomerID, setShowCustomerID] = useState(true);


  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  //const [direction, setDirection] = useState(false);
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [filterData, setFilterData] = useState({
    fullName: "",
    email: "",
    customerType: "",
    euCustomerType: "",

    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    fullName: "",
    email: "",
    customerType: "",
    euCustomerType: "",
    submit: false,
  });


  useEffect(() => {
    if (
      filterData.fullName === "" &&
      filterData.email === "" &&
      filterData.customerType === "" &&
      filterData.euCustomerType === ""
     

    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.fullName === " ") filterData.fullName = "";
    if (filterData.customerType === " ") filterData.customerType = "";
    if (filterData.email === " ") filterData.email = "";
    if (filterData.euCustomerType === " ") filterData.euCustomerType = "";
    


    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(
      localStorage.getItem("customerlist_filter")
    );
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getAllCustomers()
      : currentpolicy.fullName == "" &&
        currentpolicy.customerType == "" &&
        currentpolicy.email == "" &&
        currentpolicy.euCustomerType == "" &&
        currentpolicy.removed == false
        ? getAllCustomers()
        : console.log("current price policy!");
  }, []);



  const getAllCustomers = () => {
    setLoading(true);
    httpclient
      .get(`getCustomers?env=${env.value}&page=${page}&recordsOnPage=${recordsOnPage}&select=id,customerID,fullName,customerType,euCustomerType,mobile,email,created_at`)
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records; // Extract the array of records from the response
          setRows(records.data.map((record) => {
            const customer = {};
            columns.forEach((column) => {
              customer[column.id] = record[column.id];
            });
            return customer;
          }));
          // Set the customer data to the state
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };


  const handleSort = (column) => {
    //setDirection((prevDirection) => !prevDirection);
    setDirection(!direction);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getCustomers?env=${env.value}&fullName=${filterData.fullName
          }&customerType=${filterData.customerType
          }&email=${filterData.email
          }&euCustomerType=${filterData.euCustomerType
          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}&select=id,customerID,fullName,customerType,euCustomerType,mobile,email,created_at`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const customer = {};
              columns.forEach((column) => {
                customer[column.id] = record[column.id];
              });
              return customer;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getCustomers?env=${env.value}&sort_by=${column}&page=${page}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&select=id,customerID,fullName,customerType,euCustomerType,mobile,email,created_at`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const customer = {};
              columns.forEach((column) => {
                customer[column.id] = record[column.id];
              });
              return customer;
            }));
              setTotal(records.total);
              setRecordsOnPage(records.per_page);
              setPage(records.current_page);
              setFrom(records.from);
              setTo(records.to);
              setLoading(false);
            } else {
              console.log("Error!");
          }

        });
  };


  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient
        .get(`getCustomers?env=${env.value}&fullName=${filterData.fullName
          }&customerType=${filterData.customerType
          }&email=${filterData.email
          }&euCustomerType=${filterData.euCustomerType
          }&sort_by=${currentColumn
          }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}&select=id,customerID,fullName,customerType,euCustomerType,mobile,email,created_at`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const customer = {};
              columns.forEach((column) => {
                customer[column.id] = record[column.id];
              });
              return customer;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getCustomers?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${recordsOnPage}&page=${page}&select=id,customerID,fullName,customerType,euCustomerType,mobile,email,created_at`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const customer = {};
              columns.forEach((column) => {
                customer[column.id] = record[column.id];
              });
              return customer;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }


        });
  };


  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", +event.target.value);
    submittedData.submit
      ?
      httpclient
        .get(`getCustomers?env=${env.value}&fullName=${filterData.fullName
          }&customerType=${filterData.customerType
          }&email=${filterData.email
          }&euCustomerType=${filterData.euCustomerType
          }&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}&select=id,customerID,fullName,customerType,euCustomerType,mobile,email,created_at`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const customer = {};
              columns.forEach((column) => {
                customer[column.id] = record[column.id];
              });
              return customer;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        }) :
      httpclient
        .get(
          `getCustomers?env=${env.value}&sort_by=${currentColumn}&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${+event.target.value}&page=${page}&select=id,customerID,fullName,customerType,euCustomerType,mobile,email,created_at`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const customer = {};
              columns.forEach((column) => {
                customer[column.id] = record[column.id];
              });
              return customer;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };


  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getCustomers?env=${env.value}&customerID=${row.customerID || row}&strictFilter=true`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records.data[0]);
        setSingleLoading(false);

      });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.customerID);
      setTimeout(() => {
        getAllCustomers();
      }, 1000)
    }
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      fullName: filterData.fullName,
      customerType: filterData.customerType,
      euCustomerType: filterData.euCustomerType,
      email: filterData.email,
      status: filterData.status,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("customerlist_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.fullName ||
      filterData.customerType ||
      filterData.euCustomerType ||
      filterData.email ||
      filterData.status
    ) {
      httpclient
        .get(
          `getCustomers?env=${env.value}&fullName=${filterData.fullName
          }&customerType=${filterData.customerType
          }&euCustomerType=${filterData.euCustomerType
          }&email=${filterData.email

          }&recordsOnPage=${recordsOnPage}&page=${1}&select=id,customerID,fullName,customerType,euCustomerType,mobile,email,created_at`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records; // Extract the array of records from the response
            setRows(records.data.map((record) => {
              const customer = {};
              columns.forEach((column) => {
                customer[column.id] = record[column.id];
              });
              return customer;
            }));
            // Set the customer data to the state
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
    } else {
      getAllCustomers();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };




  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };








  const handleEdit = (row) => {
    navigate(`/update_customer/${row.id}`);
  };

  const handleAddNew = () => {
    navigate(`/save_customer`);
  };

  const handleDelete = (row) => {
    setOpenDeleteDialog(true);
    setViewDetails(row)
  };

  const sendToDelete = (call) => {
    if (call.open === false) {
      setOpenDeleteDialog(false)
      setViewDetails({})
    }
    if (call.success === true) {
      httpclient
        .post(`deleteCustomer?env=${env.value}&id=${viewDetails.id}`)
        .then(({ data }) => {
          if (data.status === 200) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenDeleteDialog(false);
            setViewDetails({});
            getAllCustomers();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  }


  const currentChange = (value, row) => {
    //console.log("value", value);
    if (value === "Update") {
      handleEdit(row);
    }
    
    if (value === "Delete") {
      handleDelete(row);
    }
  };


  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Header>
            <h1>List Customers Accounts</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>

          {/* <AddButton
            color="primary"
            variant="contained"
            onClick={handleAddNew}
          >
            <Add style={{ marginRight: "5px" }} fontSize="small" /> Add Customer
          </AddButton> */}
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>


                  <Grid item xs={12} md={6}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="fullName"
                      value={filterData.fullName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Email</InputLabel>
                    <TextField
                      variant="outlined"
                      name="email"
                      value={filterData.email}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.fullName ||
          submittedData.customerType ||
          submittedData.email ||
          submittedData.euCustomerType
           ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.fullName && (
                <p>
                  <span>Name: {submittedData.fullName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("fullName")}
                  />
                </p>
              )}
              {submittedData.euCustomerType && (
                
                  <p>
                    <span>
                  EU Type: {" "}
                  {submittedData.euCustomerType === "DOMESTIC"
                    ? "Domestic"
                    : submittedData.euCustomerType === "EU_WITH_VAT"
                    ? "EU with VAT"
                    : submittedData.euCustomerType === "OUTSIDE_EU"
                    ? "Outside EU" : "EU"}
                </span>
                  
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("euCustomerType")}
                  />
                </p>
              )}
              {submittedData.email && (
                <p>
                  <span>Email: {submittedData.email}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("email")}
                  />
                </p>
              )}
               {submittedData.customerType && (
                <p>
                 
                  <span>
                  Type: {" "}
                  {submittedData.customerType === "PERSON"
                    ? "Person"
                    : "Company"}
                </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("customerType")}
                  />
                </p>
              )}

            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}


        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            options={options}
            currentChange={currentChange}
            handleView={handleView}
            handleSort={handleSort}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            filterData={filterData}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>

      {openDeleteDialog && <DeleteDialog sendToDelete={sendToDelete} />}

      {openViewDialog && (
        <ViewDetailsDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListCustomers;

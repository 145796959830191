import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton, styled } from "@mui/material";
import { Close } from "@mui/icons-material";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

export default function DeactivateDialog(props) {
  const [dialog, setDialog] = React.useState({
    open: true,
    success: false,
  });

  React.useEffect(() => {
    props.sendDeactivate(dialog);
  }, [dialog]);

  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleSuccess = () => {
    setDialog({
      ...dialog,
      open: false,
      success: true,
    });
  };

  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          {props.viewDetails.status === 1
            ? "User Deactivate"
            : "User Activate"}

          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        <DialogContent>
          <Box pt={3}>
            <DialogContentText id="alert-dialog-description">
              {props.viewDetails.status === 1
                ? "Are you sure you want to deactive this user?"
                : "Are you sure you want to active this user?"}
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            No
          </Button>
          <Button
            onClick={handleSuccess}
            autoFocus
            style={{ marginLeft: "10px" }}
            variant="outlined"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Check, Clear, Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import TableComponent from "../../../Components/TableComponentC";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ViewMatrixDimensionDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [matrixVariationList, setMatrixVariationList] = useState([]);
  const [matrixVariationDetails, setMatrixVariationDetails] = useState("");
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });
  var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [rows, setRows] = useState([]);

  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };


  const columns = [



    { id: "variationID", name: "Erply Variation ID" },
    { id: "name", name: "Name" },
    { id: "code", name: "Code" },
    { id: "order", name: "Order" },
    { id: "active", name: "Active Status" },


  ];

  useEffect(() => {
    if (props.viewDetails.dimensionID) {
      //console.log("do i have dimension", props.viewDetails.dimensionID);
      setLoading(true);
      httpclient.get(`getMatrixDimensionVariation?env=${env.value}&dimensionID=${props.viewDetails.dimensionID}&select=dimensionID,variationID,name,code,order,active`)
        .then(({ data }) => {
          //console.log("///////", data); // log the response to the console
          if (data.status === 200) {
            const matrixVariationList = data.records.data;
            setMatrixVariationList(matrixVariationList); // set the state with the productVariationList fetched from the API
            setMatrixVariationDetails("");
            setTotal(data.records.total);
            setRecordsOnPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false); // set loading to false
          } else {
            console.log("Error!");
          }
        })
        .catch(error => {
          console.log(error); // log any errors to the console
          setLoading(false); // set loading to false
        });
    }
  }, [props.viewDetails.dimensionID]); // remove the unnecessary condition from the dependency array

  const handleSort = (column) => {
    setDirection(!direction);
    //setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    httpclient
      .get(
        `getMatrixDimensionVariation?env=${env.value}&dimensionID=${props.viewDetails.dimensionID}&select=dimensionID,variationID,name,code,order,active&sort_by=${column}&direction=${!direction ? "asc" : "desc"
        }&recordsOnPage=${recordsOnPage}&page=${page}`
      )
      .then(({ data }) => {
        if (data.status === 200) {
          const matrixVariationList = data.records.data;
          setMatrixVariationList(matrixVariationList); // set the state with the productVariationList fetched from the API
          setMatrixVariationDetails("");
          setTotal(data.records.total);
          setRecordsOnPage(data.records.per_page);
          setPage(data.records.current_page);
          setFrom(data.records.from);
          setTo(data.records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }

      });
  };
  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    httpclient
      .get(
        `getMatrixDimensionVariation?env=${env.value}&dimensionID=${props.viewDetails.dimensionID}&select=dimensionID,variationID,name,code,order,active&sort_by=${currentColumn
        }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${recordsOnPage}&page=${page}`
      )
      .then(({ data }) => {
        if (data.status === 200) {
          const matrixVariationList = data.records.data;
          setMatrixVariationList(matrixVariationList); // set the state with the productVariationList fetched from the API
          setMatrixVariationDetails("");
          setTotal(data.records.total);
          setRecordsOnPage(data.records.per_page);
          setPage(data.records.current_page);
          setFrom(data.records.from);
          setTo(data.records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }

      });


  };

  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);
    httpclient
      .get(
        `getMatrixDimensionVariation?env=${env.value}&dimensionID=${props.viewDetails.dimensionID}&select=dimensionID,variationID,name,code,order,active&sort_by=${currentColumn
        }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${+event.target.value}&page=${page}`
      )
      .then(({ data }) => {
        if (data.status === 200) {
          const matrixVariationList = data.records.data;
          setMatrixVariationList(matrixVariationList); // set the state with the productVariationList fetched from the API
          setMatrixVariationDetails("");
          setTotal(data.records.total);
          setRecordsOnPage(data.records.per_page);
          setPage(data.records.current_page);
          setFrom(data.records.from);
          setTo(data.records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }

      });


  };




  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>{`View Matrix Dimension Details (${props.viewDetails.name || "_"
            })`}</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ margin: "0", padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Dimension Variations" {...a11yProps(1)} />
              </Tabs>
            </AppBarTabs>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box pt={3}>
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Erply Dimension ID</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.dimensionID || "-"}</Values>
                    </FlexContent>



                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Name</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.name || "-"}
                      </Values>
                    </FlexContent>


                  </Grid>
                  {/* Left Side */}

                  {/* Right Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Created Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.created_at).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Added Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.added !==
                          "0000-00-00 00:00:00" ?
                          props.viewDetails.added !==
                            "1970-01-01 00:00:00"
                            ? props.viewDetails.added !== null
                              ? moment(props.viewDetails.added).format(
                                "ddd, MMM Do YYYY, h:mm a"
                              )
                              : ""
                            : ""
                          : "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Updated Date</span> <span> : </span>
                      </FlexInnerTitle>

                      <Values>
                        {props.viewDetails.updated_at !==
                          "0000-00-00 00:00:00" ?
                          props.viewDetails.updated_at !==
                            "1970-01-01 00:00:00"
                            ? props.viewDetails.updated_at !== null
                              ? moment(props.viewDetails.updated_at).format(
                                "ddd, MMM Do YYYY, h:mm a"
                              )
                              : ""
                            : ""
                          : "-"}
                      </Values>

                    </FlexContent>
                  </Grid>

                  {/* Right Side */}


                </Grid>
              </Box>

            </TabPanel>

            {/* Product Details */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              {matrixVariationList.length > 0 ? (
                <TableComponent
                  columns={columns}
                  rows={matrixVariationList}
                  sort={true}
                  handleSort={handleSort}
                  direction={direction}
                  currentColumn={currentColumn}
                  loading={loading}
                  page={page}
                  total={total && total}
                  fromTable={from}
                  toTable={to}
                  recordsOnPage={recordsOnPage}
                  handleChangePage={handleChangePage}
                  handleChangeRecordsOnPage={handleChangeRecordsOnPage}
                />
              ) : (
                <Box p={3}>
                  <span>No variation available for the dimension.</span>
                </Box>
              )}
            </TabPanel>
            {/* Product Details */}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewMatrixDimensionDialog;

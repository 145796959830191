import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import httpclient from "../../../utils/httpClient";
import env from "../../../utils/env";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import ViewProductDialog from "../ViewProductDialog";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [


  { id: "sn", name: "SN" },
  { id: "productID", name: "" },
  { id: "type", name: "Type" },
  { id: "name", name: "Name" },
  { id: "code", name: "Code" },
  { id: "groupName", name: "Group" },
  { id: "categoryName", name: "Category" },
  { id: "price", name: "Price($)" },
  { id: "status", name: "Status" },
  { id: "active", name: "State" },
 

];


const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRecordsOnPage = JSON.parse(localStorage.getItem("configRecordsOnPage"));

const ListProducts = () => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
const navigate = useNavigate();

  
  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  //const [direction, setDirection] = useState(false);
    var [direction, setDirection] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [rows, setRows] = useState([]);
 
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );


  const [recordsOnPage, setRecordsOnPage] = useState(
    configRecordsOnPage && configRecordsOnPage
      ? configRecordsOnPage && configRecordsOnPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");


  const [filterData, setFilterData] = useState({
    productID: "",
    categoryName: "",
    type: "",
    name: "",
    code: "",  
    status: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    productID: "",
    categoryName: "",
    type: "",
    name: "",
    code: "",   
    status: "",
    submit: false,
  });



  useEffect(() => {
    if (
      filterData.productID === "" &&
      filterData.categoryName === "" &&
      filterData.type === "" &&
      filterData.name === "" &&
      filterData.code === "" &&
      
      filterData.status === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.productID === " ") filterData.productID = "";
    if (filterData.categoryName === " ") filterData.categoryName = "";
    if (filterData.type === " ") filterData.type = "";
    if (filterData.name === " ") filterData.name = "";
    if (filterData.code === " ") filterData.code = "";
    
    if (filterData.status === " ") filterData.status = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(
      localStorage.getItem("productlist_filter")
    );
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getAllProducts()
      : currentpolicy.productID == "" &&
        currentpolicy.categoryName == "" &&
        currentpolicy.type == "" &&
        currentpolicy.name == "" &&
        currentpolicy.code == "" &&      
        currentpolicy.status == "" &&
        currentpolicy.removed == false
        ? getAllProducts()
        : console.log("current price policy!");
  }, []);

 



  const getAllProducts = () => {
    setLoading(true);
    httpclient.get(`getProducts?recordsOnPage=${recordsOnPage}&page=${page}&select=productID,name,type,code,groupName,categoryName,price,active,status`)
      // &includeMatrixVariations=${0}  &select=productID,name
      .then(({ data }) => {
        if (data.status === 200) {
          const records = data.records;
          setRows(records.data.map((record) => {
            const product = {};
            columns.forEach((column) => {
              product[column.id] = record[column.id];
            });
            return product;
          }));
          setTotal(records.total);
          setRecordsOnPage(records.per_page);
          setPage(records.current_page);
          setFrom(records.from);
          setTo(records.to);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };

  
  

  const handleSort = (column) => {
     setDirection(!direction);
    //setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ?
      httpclient
        .get(
          `getProducts?productID=${filterData.productID
          }&categoryName=${filterData.categoryName
          }&type=${filterData.type
          }&name=${filterData.name
          }&code=${filterData.code
          }&status=${filterData.status
          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&page=${page}&select=productID,name,type,code,groupName,categoryName,price,active,status`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      : httpclient
        .get(
          `getProducts?sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&recordsOnPage=${recordsOnPage}&select=productID,name,type,code,groupName,categoryName,price,active,status`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            //   setTotal(records.total);
            //   setRecordsOnPage(records.per_page);
            //   setPage(records.current_page);
            //   setFrom(records.from);
            //   setTo(records.to);
            //   setLoading(false);
            // } else {
            //   console.log("Error!");
          }

        });
  };




  const handleChangePage = (event, page) => {

    //setPage(page);
    setLoading(true);
    submittedData.submit
      ?
      httpclient.get(`getProducts?productID=${filterData.productID
        }&categoryName=${filterData.categoryName
        }&type=${filterData.type
        }&name=${filterData.name
        }&code=${filterData.code
        }&status=${filterData.status
        }&sort_by=${currentColumn
        }&direction=${direction !== "" ? direction ? "asc" : "desc" : ""
        }&recordsOnPage=${recordsOnPage}&page=${page}&select=productID,name,type,code,groupName,categoryName,price,active,status`
      )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getProducts?direction=${direction !== "" ? direction ? "asc" : "desc" : ""
          }&recordsOnPage=${recordsOnPage}&page=${page}&select=productID,name,type,code,groupName,categoryName,price,active,status`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };



  const handleChangeRecordsOnPage = (event) => {
    //const recordsOnPage = +event.target.value;
    setRecordsOnPage(+event.target.value);
    localStorage.setItem("configRecordsOnPage", event.target.value);

    submittedData.submit
      ?
      httpclient
        .get(`getProducts?productID=${filterData.productID === "" ? "" : filterData.productID
          }&categoryName=${filterData.categoryName
          }&type=${filterData.type
          }&name=${filterData.name
          }&code=${filterData.code
          }&status=${filterData.status
          }&sort_by=${currentColumn}
          &direction=${direction ? "asc" : "desc"
          }&page=${page}&recordsOnPage=${+event.target.value}&select=productID,name,type,code,groupName,categoryName,price,active,status`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        })
      :
      httpclient
        .get(
          `getProducts?direction=${direction ? "asc" : "desc"
          }&recordsOnPage=${+event.target.value}&page=${1}&select=productID,name,type,code,groupName,categoryName,price,active,status`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error!");
          }

        });
  };


  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`getProducts?productID=${row.productID || row}`)
      .then(({ data }) => {
        if (data.records)
          setViewDetails(data.records.data[0]);

        setSingleLoading(false);
        //console.log("data", data.records.data[0])

      });
  };

  





  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.productID);
      setTimeout(() => {
        getAllProducts();
      }, 1000)
    }
  };

 


  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
     
      categoryName: filterData.categoryName,
      productID: filterData.productID,
      type: filterData.type,
      name: filterData.name,
      code: filterData.code,
     
      status: filterData.status,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("productlist_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      
      filterData.categoryName ||
      filterData.productID ||
      filterData.type ||
      filterData.name ||
      filterData.code ||
      
      filterData.status
    ) {
      httpclient
        .get(
          `getProducts?productID=${filterData.productID
          }&categoryName=${filterData.categoryName
          }&type=${filterData.type
          }&name=${filterData.name
          }&code=${filterData.code
          }&status=${filterData.status
          }&recordsOnPage=${recordsOnPage}&page=${1}&select=productID,name,type,code,groupName,categoryName,price,active,status`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            const records = data.records;
            
            setRows(records.data.map((record) => {
              const product = {};
              columns.forEach((column) => {
                product[column.id] = record[column.id];
              });
              return product;
            }));//setRows(records.data);
            setTotal(records.total);
            setRecordsOnPage(records.per_page);
            setPage(records.current_page);
            setFrom(records.from);
            setTo(records.to);
            setLoading(false);
          } else {
            console.log("Error12345!");
          }
        });
    } else {
      getAllProducts();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };

    


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Products</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                {/* <Grid item xs={12} md={6}>
                    <InputLabel>Product</InputLabel>
                    <TextField
                      variant="outlined"
                      name="productID"
                      value={filterData.productID}
                      onChange={handleChangeFilter}
                      fullWidth
                    />
                  </Grid> */}

                  <Grid item xs={12} md={6}>
                    <InputLabel>Category</InputLabel>
                    <TextField
                      variant="outlined"
                      name="categoryName"
                      value={filterData.categoryName}
                      onChange={handleChangeFilter}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="name"
                      value={filterData.name}
                      onChange={handleChangeFilter}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Code</InputLabel>
                    <TextField
                      variant="outlined"
                      name="code"
                      value={filterData.code}
                      onChange={handleChangeFilter}
                      fullWidth
                    />
                  </Grid>
                  

                  <Grid item xs={12} md={6}>
                    <InputLabel>Status</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.status}
                        // label="Exclusive Pending"
                        name="status"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                       
                        <MenuItem value={"ACTIVE"}>Active</MenuItem>
                        <MenuItem value={"NO_LONGER_ORDERED"}>No longer Ordered</MenuItem>
                        <MenuItem value={"NOT_FOR_SALE"}>Not for sale</MenuItem>
                        <MenuItem value={"ARCHIVED"}>Archived</MenuItem>
                        <MenuItem value={"ALL_EXCEPT_ARCHIVED"}>All Except Archived</MenuItem>

                      </Select>
                    </FormControl>
                  </Grid>



                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.productID ||
          submittedData.categoryName ||
          submittedData.type ||
          submittedData.name ||
          submittedData.code ||
          //submittedData.locationInWarehouseName ||
          submittedData.status ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.productID && (
                <p>
                  <span>Product: {submittedData.productID}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("productID")}
                  />
                </p>
              )}
              {submittedData.categoryName && (
                <p>
                  <span>Category: {submittedData.categoryName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("categoryName")}
                  />
                </p>
              )}
              {submittedData.name && (
                <p>
                  <span>Name: {submittedData.name}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("name")}
                  />
                </p>
              )}
              {submittedData.code && (
                <p>
                  <span>Code: {submittedData.code}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("code")}
                  />
                </p>
              )}
              {submittedData.type && (
                <p>
                  <span>Type: {submittedData.type}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("type")}
                  />
                </p>
              )}

              

              {submittedData.status && (
                <p>
                  <span>
                    Status: {submittedData.status}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("status")}
                  />
                </p>
              )}
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true} 
            handleSort={handleSort}
            handleView={handleView}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            recordsOnPage={recordsOnPage}
            filterData={filterData}
            handleChangePage={handleChangePage}
            handleChangeRecordsOnPage={handleChangeRecordsOnPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewProductDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListProducts;

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import theme from "../../../theme/theme";
import httpclient from "../../../utils/httpClient";
import qs from 'qs';
import Global from "../../../utils/GlobalValues";
import { fontSize } from "@mui/system";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SubmitDiv = styled("div")(({ theme }) => ({
  "& .MuiButton-root": {
    color: "#fff",
    marginTop: "10px",
    padding: "10px",
    fontSize: "15px"
  },
}));

const Form = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [snackStatus, setSnackStatus] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [severity, setSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [registerData, setRegisterData] = useState({
    // email: "admin@gmail.com",
    // password: "admin123",
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    password_confirmation: "",
    status: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleKeyPassword = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  }
  function handleLoginClick() {
    navigate("/#/user-login");
  }

  const handleSubmit = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("first_name", registerData.first_name);
    formData.append("last_name", registerData.last_name);
    formData.append("email", registerData.email);
    formData.append("password", registerData.password);
    formData.append("password_confirmation", registerData.password_confirmation);
    formData.append("status", 1);


    httpclient.post('/registerUser', formData).then(({ data }) => {
      console.log(data);
      if (data.status === 200) {
        //localStorage.setItem("register-user", JSON.stringify(data.data.user));
        //localStorage.setItem("token", JSON.stringify(data.data.access_token));
        setOpen(true);
        setSnackStatus("success");
        setSnackMessage("Registered successfully!");
        setLoading(false);
        setTimeout(() => {
          window.location = "/#/user-login";
        }, 1000);
      } else {
        setLoading(false);
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage(!data.error ? "Invalid Credentials!!!!" : data.error);

      }
    });

  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          {/* <h2
                style={{
                  margin: "0",
                  paddingBottom: "20px",
                  fontSize: "35px",
                }}
              >
                Login
              </h2> */}
          <img src="/psw_logo.jpg" alt="PSW_Sync_Care" style={{ width: "100%", objectFit: "cover" }} />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel >First Name</InputLabel>
          <TextField
            variant="outlined"
            // label="Email"
            name="first_name"
            type="text"
            value={registerData.first_name}
            onChange={(e) => handleChange(e)}
            style={{
              width: "100%",
              borderRadius: "5px",
              background: theme.palette.primary.lighter,
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel >Last Name</InputLabel>
          <TextField
            variant="outlined"
            // label="Email"
            name="last_name"
            type="text"
            value={registerData.last_name}
            onChange={(e) => handleChange(e)}
            style={{
              width: "100%",
              borderRadius: "5px",
              background: theme.palette.primary.lighter,
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel >Email</InputLabel>
          <TextField
            variant="outlined"
            // label="Email"
            name="email"
            type="text"
            value={registerData.email}
            onChange={(e) => handleChange(e)}
            style={{
              width: "100%",
              borderRadius: "5px",
              background: theme.palette.primary.lighter,
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel >Password</InputLabel>
          <TextField
            variant="outlined"
            // label="Password"
            name="password"
            type="password"
            value={registerData.password}
            onKeyDown={handleKeyPassword}
            onChange={(e) => handleChange(e)}
            style={{
              width: "100%",
              borderRadius: "5px",
              background: theme.palette.primary.lighter,
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <InputLabel >Password Confirmation</InputLabel>
          <TextField
            variant="outlined"
            // label="Password"
            name="password_confirmation"
            type="password"
            value={registerData.password_confirmation}
            onKeyDown={handleKeyPassword}
            onChange={(e) => handleChange(e)}
            style={{
              width: "100%",
              borderRadius: "5px",
              background: theme.palette.primary.lighter,
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
              <span >Forgot Password?</span>
            </Grid> */}
        <Grid item xs={12} md={12}>
          <SubmitDiv>
            {loading ?
              <Button
                variant="contained"
                color="secondary"
                // disabled
                fullWidth
              >
                <CircularProgress style={{ height: "20px", width: "20px", color: "#fff", marginRight: "10px" }} /> Loading
              </Button>
              :
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                fullWidth
              >
                Register
              </Button>
            }
          </SubmitDiv>
        </Grid>
        <Box display="flex" justifyContent="space-between" alignItems="center" textAlign="left" width="100%" mt={3}>
          <small><a href="/#/user-login" onClick={handleLoginClick}>Click here to login!</a></small>
          <small>ver: {Global.version}</small>
        </Box>

      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackStatus}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Form;

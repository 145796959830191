import React from 'react'
import {
   BrowserRouter,
  HashRouter, Navigate, Route, Routes
} from 'react-router-dom';
import MiniDrawer from '../Components/Drawer';
import ListCustomers from '../Private/Customers/ListCustomers';
import ListCustomerGroups from '../Private/Customers/ListCustomerGroups';
import Home from '../Private/Home';
import AddUser from '../Private/Members/UpdateUser';
import ListUsers from '../Private/Members/ListUsers';
import WebsiteSettings from '../Private/WebsiteSettings';
import ListProductVariations from '../Private/Products/ListProductVariations'
import ListProductCategories from '../Private/Products/ListProductCategories';
import ListProductGroups from '../Private/Products/ListProductGroups';
import ListWarehouses from '../Private/Warehouses/ListWarehouses';
import Login from '../Public/Login';
import Register from '../Public/Register';
import UpdateCustomerGroup from '../Private/Customers/UpdateCustomerGroup';
import UpdateCustomer from '../Private/Customers/UpdateCustomer';
import SaveCustomerGroup from '../Private/Customers/SaveCustomerGroup';
import SaveCustomer from '../Private/Customers/SaveCustomer';
import MainProductPage from '../Private/Products/MainProductPage';
import ListInventoryRegistrations from '../Private/Inventories/ListInventoryRegistrations';
import ListInventoryWriteOff from '../Private/Inventories/ListInventoryWriteOff';
import ListInventoryTransfer from '../Private/Inventories/ListInventoryTransfer';
import ListMatrixDimensionVariation from '../Private/Products/ListMatrixDimensionVariation';
import ListMatrixDimension from '../Private/Products/ListMatrixDimension';
import ListReasonCodes from '../Private/Inventories/ListReasonCodes';
import ListEmployees from '../Private/Employees/ListEmployees';
import ListPayment from '../Private/Payments/ListPayment';
import ListOpeningClosingDays from '../Private/Warehouses/ListOpeningClosingDays';
import ListSalesTransaction from '../Private/Transactions/ListSalesTransaction';
import ListPurchaseTransaction from '../Private/Transactions/ListPurchaseTransaction';
// import Pos from '../Private/Pos';
import ResyncSchool from '../Private/Sync/ResyncSchool';
import ResyncStore from '../Private/Sync/ResyncStore';
import ResyncProduct from '../Private/Sync/ResyncProduct';
import ResyncGenericProduct from '../Private/Sync/ResyncGenericProduct';
import ResyncSOH from '../Private/Sync/ResyncSOH';


const PrivateRoute = ({ children }) => {
  return localStorage.getItem("token") ? (
    <MiniDrawer children={children} />
  ) : (
    <Navigate to="/user-login"></Navigate>
  );
};

const Routing = () => {
 
  var loginData = localStorage.getItem("user-login");
  var loginValue = JSON.parse(loginData);
  return (
    //  <BrowserRouter>
    
      <HashRouter>
      <Routes>
        {/* HOME */}
        <Route path='/' element={<PrivateRoute> <Home /> </PrivateRoute>} />
        {/* HOME */}

        {/* Customers */}
        {/* List Customers */}
        <Route path="/customers/list_customers" element={<PrivateRoute> <ListCustomers /> </PrivateRoute>} />
        <Route path="/customers/list_customer_groups" element={<PrivateRoute> <ListCustomerGroups /> </PrivateRoute>} />
        <Route path="/update_customer_group/:id" element={<PrivateRoute> <UpdateCustomerGroup /> </PrivateRoute>} />
        <Route path="/update_customer/:id" element={<PrivateRoute> <UpdateCustomer /> </PrivateRoute>} />
        <Route path="/save_customer_group" element={<PrivateRoute> <SaveCustomerGroup /> </PrivateRoute>} />
        <Route path="/save_customer" element={<PrivateRoute> <SaveCustomer /> </PrivateRoute>} />
        {/* Customers */}

        {/* Products */}
        {/* List productss */}
        <Route path="/products/list_product_variations" element={<PrivateRoute> <ListProductVariations /> </PrivateRoute>} />
        <Route path="/products/list_product_categories" element={<PrivateRoute> <ListProductCategories /> </PrivateRoute>} />
        <Route path="/products/list_product_groups" element={<PrivateRoute> <ListProductGroups /> </PrivateRoute>} />
        <Route path="/products/list_matrix_dimension_variation" element={<PrivateRoute> <ListMatrixDimensionVariation /> </PrivateRoute>} />
        <Route path="/products/list_matrix_dimension" element={<PrivateRoute> <ListMatrixDimension /> </PrivateRoute>} />
        {/* Products */}

        {/* Inventories */}
        <Route path="/list_inventory_registrations" element={<PrivateRoute> <ListInventoryRegistrations /> </PrivateRoute>} />
        <Route path="/list_inventory_writeOff" element={<PrivateRoute> <ListInventoryWriteOff /> </PrivateRoute>} />
        <Route path="/list_inventory_transfer" element={<PrivateRoute> <ListInventoryTransfer /> </PrivateRoute>} />
        <Route path="/list_reason_codes" element={<PrivateRoute> <ListReasonCodes /> </PrivateRoute>} />
        {/* Inventories */}

        {/* users */}
        {loginValue && loginValue.type === "superadmin" &&
          <>
            <Route path="/get_users" element={<PrivateRoute> <ListUsers /> </PrivateRoute>} />

            <Route path="/add_user" element={<PrivateRoute> <AddUser /> </PrivateRoute>} />
          </>
        }
        <Route path="/get_user/:id" element={<PrivateRoute> <AddUser /> </PrivateRoute>} />
        {/* users */}

        {/* settings */}
        <Route path="/settings/website_settings" element={<PrivateRoute> <WebsiteSettings /> </PrivateRoute>} />
        {/* settings */}

        {/* warehouses */}
        <Route path="/warehouses/list_warehouses" element={<PrivateRoute> <ListWarehouses /> </PrivateRoute>} />
        <Route path="/warehouses/list_opening_closing_days" element={<PrivateRoute> <ListOpeningClosingDays /> </PrivateRoute>} />
        {/* warehouses */}

        {/* employees */}
        <Route path="/employees/list_employees" element={<PrivateRoute> <ListEmployees /> </PrivateRoute>} />

        {/* employees */}
        {/* payment */}
        <Route path="/payments/list_payment" element={<PrivateRoute> <ListPayment /> </PrivateRoute>} />
        {/* payment */}

        {/* sales Transaction */}
        <Route path="/transactions/list_sales_transaction" element={<PrivateRoute> <ListSalesTransaction /> </PrivateRoute>} />
        <Route path="/transactions/list_purchase_transaction" element={<PrivateRoute> <ListPurchaseTransaction /> </PrivateRoute>} />
        {/* sales transation */}

        {/* sync */}
        <Route path="/sync/resync_school" element={<PrivateRoute> <ResyncSchool /> </PrivateRoute>} />
        <Route path="/sync/resync_store" element={<PrivateRoute> <ResyncStore /> </PrivateRoute>} />
        <Route path="/sync/resync_product" element={<PrivateRoute> <ResyncProduct /> </PrivateRoute>} />
        <Route path="/sync/resync_generic_product" element={<PrivateRoute> <ResyncGenericProduct /> </PrivateRoute>} />
        <Route path="/sync/resync_soh" element={<PrivateRoute> <ResyncSOH /> </PrivateRoute>} />
        {/* sync */}
        {/* sync */}

        {/* user-authentication */}
        {/* <Route path="/pos" element={<PrivateRoute> <Pos /> </PrivateRoute>} /> */}

        <Route path="/user-login" element={<Login />} />
        <Route path="/register-user" element={<Register />} />

        {/*user-authentication */}
      </Routes>
      </HashRouter>
    //</BrowserRouter> 
 
  )
}

export default Routing
import {
  Box, Card, Grid, styled, InputLabel,
  MenuItem,
  Select, FormControl, TextField, Button
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from "../../theme/theme";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const MyCard = styled(Card)(({ theme }) => ({
  padding: "20px",
  "& h3": {
    margin: "0"
  }
}))

const WebsiteSettings = () => {

  const [seconds, setSeconds] = useState(60)
  const [showContent, setShowContent] = useState(true)
  const [showContent1, setShowContent1] = useState(true)

  const handleChange = (e) => {
    const { name, value } = e.target;

  };

  return (

    <>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>

            <div className="grid-block">
              <div className="grid-block-header" onClick={() => setShowContent(!showContent)}>
                Calculate SOH from below branches
                <div className="arrow-icon">{showContent ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
              </div>
              {showContent && (
                <div className="grid-block-content">
                  Selected Branches are used for SOH Calculation
                </div>
              )}
            </div>


          </Grid>

          <Grid item xs={12} md={6}>

            <div className="grid-block">
              <div className="grid-block-header" onClick={() => setShowContent1(!showContent1)}>
                Customer Groups
                <div className="arrow-icon">{showContent1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
              </div>
              {showContent1 && (
                <div className="grid-block-content">
                  Selected Customer group you want to use

                  <ul class="list-content" type="none">
                    <li>Reseller (323)
                    </li>
                    <li>
                      Retail (1202)
                    </li>
                    <li>
                      Wholesale (4266)
                    </li>

                  </ul>
                </div>
              )}


            </div>

          </Grid>

          <Grid item xs={12} md={6}>

            <div className="grid-block">
              <div className="grid-block-header">
                Default Price (Active Price)
              </div>
              <div className="grid-block-content">
                {/* <Grid item xs={12} > */}
                Select Default Price
                <FormControl fullWidth>
                  <Select
                    //value={filterData.order_status}

                    name="Select Default Price"
                    onChange={(e) => handleChange(e)}
                  //onChange={handleChangeFilter}
                  >
                    {/* <MenuItem value={""}>Select</MenuItem> */}
                    <MenuItem value={"Retail Price WGST"}>Retail Price WGST</MenuItem>
                    <MenuItem value={"Retail Sale Price WGST"}>Retail Sale Price WGST</MenuItem>

                  </Select>
                </FormControl>
                {/* </Grid> */}

              </div>


            </div>

          </Grid>

          <Grid item xs={12} md={6}>

            <div className="grid-block">
              <div className="grid-block-header">
                Default Sale Price
              </div>
              <div className="grid-block-content">
                {/* <Grid item xs={12} > */}
                Select Default Price
                <FormControl fullWidth>
                  <Select
                    //value={filterData.order_status}

                    name="Select Default Price"
                    onChange={(e) => handleChange(e)}
                  //onChange={handleChangeFilter}
                  >
                    {/* <MenuItem value={""}>Select</MenuItem> */}
                    <MenuItem value={"Retail Price WGST"}>Retail Price WGST</MenuItem>
                    <MenuItem value={"Retail Sale Price WGST"}>Retail Sale Price WGST</MenuItem>

                  </Select>
                </FormControl>
                {/* </Grid> */}

              </div>


            </div>

          </Grid>

          <Grid item xs={12} md={6}>

            <div className="grid-block">
              <div className="grid-block-header">
                All Schools belong to Below Main Category
              </div>
              <div className="grid-block-content">
                School Parent Category Name

                <Grid item md={12} xs={12}>

                  <TextField
                    variant="outlined"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      background: theme.palette.primary.lighter,
                    }}
                  />
                </Grid>
              </div>


            </div>

          </Grid>
        </Grid>

        <Grid
          item
          md={6}
          xs={12}

        >
          <Button style={{ marginTop: "20px", width: "40%" }} color="primary" variant="contained" >
            Update <ArrowCircleRightIcon style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>


        <style jsx>{`
  .grid-block {
    border: 1px solid #ccc;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
    padding: 0px;
    background-color: #fff;
    border-radius: 5px;
    
   
  }

  .grid-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding:10px;
    margin-top: 0.3px;
    background-color: #014868;
    color: #fff;
    border-radius: 3px;
    height: 50px; 
   
    font-size: 14px;
  }

  .grid-block-header .arrow-icon {
    display: inline-block;
    float: right;
  }

  .grid-block-content {
    display: grid;
    width: 100%;
    height: auto;
    padding:15px;
    grid-template-columns: 1fr, auto;
    gap: 1px;
   
    font-size: 14px;
    font-weight: bold;
  }

 .list-content{
   
  }

  .grid-item.title {
  
    background-color: #eee;
    padding: 16px;
  }
  .grid-item.content {
    background-color: #eee;
    padding: 16px;
  }

  @media (max-width: 768px) {
    .grid-block-header {
      display: flex;
      flex-direction: column;
      font-size: 12px;
    }
    
    
  }
  
`}</style>
      </div>
    </>
  )
}

export default WebsiteSettings